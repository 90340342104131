import { Typography } from "@mui/material";
import Features from "./Features";
import TestPatterns from "./TestPatterns";
import HeadMountedDeviceGuide from "./HeadMountedDeviceGuide";
import PortalGuide from "./PortalGuide";
import { useTranslation } from "react-i18next";

const GettingStarted = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h5" component="h2" gutterBottoma fontWeight={"bold"}>
        3. {t("operating_manual_getting_started_title")}
      </Typography>
      <Features />
      <TestPatterns />
      <HeadMountedDeviceGuide />
      <PortalGuide />
    </>
  );
};

export default GettingStarted;
