import { Typography } from "@mui/material";
import SetUpWiFi from "./SetUpWiFi";
import VRDeviceCare from "./VRDeviceCare";
import { useTranslation } from "react-i18next";

const VirtualRealityDevice = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h5" gutterBottom fontWeight={"bold"}>
        5. {t("operating_manual_vr_device_title")}
      </Typography>
      <SetUpWiFi />
      <VRDeviceCare />
    </>
  );
};

export default VirtualRealityDevice;
