import { Box, Typography } from "@mui/material";
import { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { t } from "i18next";

const AccountRegistration = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  const onClickUrl = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div
      id="account-registration"
      style={sectionContainerStyle}
      className="px-5"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
          color: "white",
          p: 1,
          pl: 4,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        {" "}
        <Typography variant="h6" fontWeight="bold" color="white">
          4.1. {t("operating_manual_account_registrations_title")}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pl: 4,
          pr: 4,
          gap: 2,
        }}
      >
        <Typography>
          {t("operating_manual_account_registrations_description1")}{" "}
          <Link to="http://portal.retinalogik.ca/" target="_blank">
            portal.retinalogik.ca
          </Link>
          {". "}
          {t("operating_manual_account_registrations_description2")}{" "}
          support@retinalogik.ca.
        </Typography>

        <Typography>
          {t("operating_manual_account_registrations_description3")}{" "}
          <Link
            to="/operating-manual/virtual-reality-device/"
            onClick={onClickUrl}
          >
            {t("operating_manual_account_registrations_description4")}
          </Link>{" "}
          {t("operating_manual_account_registrations_description5")}
        </Typography>
      </Box>
    </div>
  );
};

export default AccountRegistration;
