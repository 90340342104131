import { createContext, useState } from "react";

const ToastContext = createContext({
  toast: { success: false, message: null },
  setToast: () => {},
});

const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState({ success: false, message: null });

  return (
    <ToastContext.Provider
      value={{
        toast: toast,
        setToast: setToast,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export { ToastContext, ToastProvider };
