import { Typography, Box, Grid } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Description1 from "../../../../../assets/images/operating-manual/4/description4.3.png";
import Description2 from "../../../../../assets/images/operating-manual/4/description4.3a.png";
import Description3 from "../../../../../assets/images/operating-manual/4/description4.3b.png";
import Description4 from "../../../../../assets/images/operating-manual/4/description4.3c.png";
import Description5 from "../../../../../assets/images/operating-manual/4/description4.3d.png";

const TurningOnVRDevice = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <Box
      id="turning-on-vr-device"
      sx={{ ...sectionContainerStyle, mt: 3, padding: { xs: 2, sm: 3 } }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
          color: "white",
          p: 1,
          pl: 4,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
          flexDirection: { xs: "column", sm: "row" },
          textAlign: { xs: "center", sm: "left" },
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          4.3. {t("operating_manual_turning_on_vr_device_title")}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pl: 4,
          pr: 4,
          gap: 2,
        }}
      >
        <Grid container xs={12}>
          <Grid item xs={12} md={10}>
            <Box sx={{ display: "inline-flex", alignItems: "center" }}>
              1.{" "}
              {t("operating_manual_turning_on_vr_device_note_description1_a")}
              <Box
                component="img"
                src={Description1}
                alt="power-button"
                sx={{
                  mx: 1,
                  maxWidth: { xs: "12%", md: "6%" },
                  height: "auto",
                }}
              />
              {t("operating_manual_turning_on_vr_device_note_description1_b")}
              <Box
                component="img"
                src={Description5}
                alt="blue-indicator"
                sx={{
                  mx: 1,
                  maxWidth: { xs: "12%", md: "6%" },
                  height: "auto",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              component="img"
              src={Description3}
              alt="Description 3"
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>

        <Grid container xs={12}>
          <Grid item xs={12} md={10}>
            <Box sx={{ display: "inline-flex", alignItems: "center" }}>
              2.{" "}
              {t("operating_manual_turning_on_vr_device_note_description2_a")}
              <Box
                component="img"
                src={Description2}
                alt="pico-button"
                sx={{
                  mx: 1,
                  maxWidth: { xs: "12%", md: "6%" },
                  height: "auto",
                }}
              />
              {t("operating_manual_turning_on_vr_device_note_description2_b")}
              <Box
                component="img"
                src={Description5}
                alt="blue-indicator"
                sx={{
                  mx: 1,
                  maxWidth: { xs: "12%", md: "6%" },
                  height: "auto",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              component="img"
              src={Description4}
              alt="Description 4"
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          p: 2,
          mt: 2,
          backgroundColor: "grey.100",
          color: "text.secondary",
          borderRadius: 1,
        }}
      >
        {t("operating_manual_turning_on_vr_device_note")}
      </Box>
    </Box>
  );
};

export default TurningOnVRDevice;
