// Library methods
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";

// Utilities
import { getPatientById } from "../../../services/Patient";
import {
  // addCommentReport,
  getCommentsReport,
  getExamById,
  getSubmisionData,
} from "../../../services/Exam";
import {
  calculateDurationForDisplay,
  getExamType,
  isMonocularMulitpleSections,
} from "../../../utils/examReportsHelper";
import { sectionCompletionState } from "../../../hooks/useExamStatus";
import { getClinics } from "../../../services/Clinic";
import { EyeContext } from "../../../contexts/ExamPropertyContext";

// const ReportPdf = (pdfToken = null) => {
const ReportPdf = (isPdf = false, clinicId, patientId, examId) => {
  // auth
  const { getAccessTokenSilently } = useAuth0();

  // navigate
  const navigate = useNavigate();

  const { eyeRight } = useContext(EyeContext);

  // indicates if the data has been fetched, or if it is still being fetched
  const [isLoading, setIsLoading] = useState(true);

  const [patient, setPatient] = useState({
    sex: "",
    age: "",
    ethinicty: "",
    firstName: "",
    lastName: "",
  });
  const [exam, setExam] = useState({});
  const [examReport1, setExamReport1] = useState(undefined);
  const [examReport2, setExamReport2] = useState(undefined);
  const [eyeReport1, setEyeReport1] = useState("");
  const [eyeReport2, setEyeReport2] = useState("");
  // const [commentsTupple, setCommentsTupple] = useState([[], []]);
  const [commentData1, setCommentData1] = useState([]);
  const [commentData2, setCommentData2] = useState([]);
  const [shouldDisplayReport, setShouldDisplayReport] = useState(false);

  const [sectionId1, setSectionId1] = useState("");
  const [sectionId2, setSectionId2] = useState("");

  const [duration1, setDuration1] = useState("");
  const [duration2, setDuration2] = useState("");
  const [clinicTimezone, setClinicTimezone] = useState(null);
  const [newPatientId, setNewPatientId] = useState(patientId);

  const durationStartTime1 = useMemo(() => {
    let res = "00:00";
    if (!duration1) return res;
    if (duration1.length > res.length) return res + ":00";
    return res;
  }, [duration1]);
  const durationStartTime2 = useMemo(() => {
    let res = "00:00";
    if (!duration2) return res;
    if (duration2.length > res.length) return res + ":00";
    return res;
  }, [duration2]);
  const getComments = useCallback(
    async (token, sectionId, i, signal) => {
      /**
       * commentData: [{
       *    "content": string,
       *    "conditions": `[string]`,
       *    "otherConditions": string
       * }]
       */
      const commentsReportData = await getCommentsReport(
        token,
        clinicId,
        newPatientId,
        examId,
        sectionId,
        signal,
        isPdf
      );
      return commentsReportData;
    },
    [clinicId, examId, isPdf, newPatientId]
  );

  const fetchReportData = useCallback(
    async (token, sectionId, signal) => {
      try {
        const submission = await getSubmisionData(
          token,
          clinicId,
          newPatientId,
          examId,
          sectionId,
          signal,
          isPdf
        );
        const parsedSubmission = JSON.parse(submission);
        return parsedSubmission;
      } catch (e) {
        return undefined;
      }
    },
    [clinicId, examId, isPdf, newPatientId]
  );

  // method that fetches the data
  const fetch = useCallback(
    async (signal) => {
      try {
        // get authToken
        let token = null;
        if (!isPdf) token = await getAccessTokenSilently();
        // const token = pdfToken ? pdfToken : accessToken;

        // get the patient and the exam's data
        const fetchPatient = getPatientById(
          token,
          clinicId,
          newPatientId,
          signal,
          isPdf
        );
        const fetchExam = getExamById(
          token,
          clinicId,
          newPatientId,
          examId,
          signal,
          isPdf
        );
        const [patientData, examData] = await Promise.all([
          fetchPatient,
          fetchExam,
        ]);

        // set the patient and exam states
        setPatient(patientData);
        setExam(examData);
        setNewPatientId(examData.patient.id);

        const examSectionsKeys = Object.keys(examData).filter((key) =>
          key.includes("Sections")
        );
        const currentExamSectionsKey = examSectionsKeys.filter(
          (key) => examData[key].length > 0
        );
        if (currentExamSectionsKey.length !== 1) return false;

        const shouldDoFirstSection =
          !isMonocularMulitpleSections(examData) ||
          examData[currentExamSectionsKey[0]]?.[0]?.eye === eyeRight;
        let sectionId = shouldDoFirstSection
          ? examData[currentExamSectionsKey[0]]?.[0]?.id
          : examData[currentExamSectionsKey[0]]?.[1]?.id;

        // get exam report 1
        const examReportContent1 = await fetchReportData(
          token,
          sectionId,
          signal
        );

        if (examReportContent1) {
          setCommentData1(
            await getComments(
              token,
              sectionId,
              shouldDoFirstSection ? 1 : 0,
              signal
            )
          );
          setExamReport1(examReportContent1);
          setEyeReport1(
            shouldDoFirstSection
              ? examData[currentExamSectionsKey[0]]?.[0]?.eye
              : examData[currentExamSectionsKey[0]]?.[1]?.eye
          );
          setSectionId1(sectionId);
        }

        // get exam report 2, if available
        if (isMonocularMulitpleSections(examData)) {
          const examSectionsKeys = Object.keys(examData).filter((key) =>
            key.includes("Sections")
          );
          const currentExamSectionsKey = examSectionsKeys?.filter(
            (key) => examData[key].length > 1
          );
          sectionId = shouldDoFirstSection
            ? examData[currentExamSectionsKey[0]]?.[1]?.id
            : examData[currentExamSectionsKey[0]]?.[0]?.id;

          const examReportContent2 = await fetchReportData(
            token,
            sectionId,
            signal
          );

          if (examReportContent2) {
            setCommentData2(
              await getComments(
                token,
                sectionId,
                shouldDoFirstSection ? 0 : 1,
                signal
              )
            );
            setExamReport2(examReportContent2);
            setEyeReport2(
              shouldDoFirstSection
                ? examData[currentExamSectionsKey[0]]?.[1]?.eye
                : examData[currentExamSectionsKey[0]]?.[0]?.eye
            );
            setSectionId2(sectionId);
          }
        }
      } catch (e) {
        // console.log(e);
        if (isPdf) navigate("/401");
      }
    },
    [
      isPdf,
      getAccessTokenSilently,
      clinicId,
      newPatientId,
      examId,
      fetchReportData,
      getComments,
      navigate,
      eyeRight,
    ]
  );

  // method that does the initial data fetching, showing the loading indicator
  const initialFetch = useCallback(
    async (signal) => {
      try {
        setIsLoading(true);
        await fetch(signal);
      } catch (e) {
        //console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [fetch]
  );

  const refetchComment = useCallback(
    async (signal) => {
      try {
        let token = null;
        if (!isPdf) token = await getAccessTokenSilently();

        const examSectionsKeys = Object.keys(exam).filter((key) =>
          key.includes("Sections")
        );
        const currentExamSectionsKey = examSectionsKeys.filter(
          (key) => exam[key].length > 0
        );
        if (currentExamSectionsKey.length !== 1) return false;

        const shouldDoFirstSection =
          !isMonocularMulitpleSections(exam) ||
          exam[currentExamSectionsKey[0]]?.[0]?.eye === eyeRight;
        // const token = pdfToken ? pdfToken : accessToken;
        if (sectionId1)
          setCommentData1(
            await getComments(
              token,
              sectionId1,
              shouldDoFirstSection ? 0 : 1,
              signal
            )
          );
        if (sectionId2)
          setCommentData2(
            await getComments(
              token,
              sectionId2,
              shouldDoFirstSection ? 1 : 0,
              signal
            )
          );
      } catch (e) {
        // if (pdfToken) navigate("/404");
        if (isPdf) navigate("/401");
      }
    },
    [
      isPdf,
      getAccessTokenSilently,
      exam,
      eyeRight,
      sectionId1,
      getComments,
      sectionId2,
      navigate,
    ]
  );

  // whether the report should be displayed or not
  const validateExamReports = useCallback(
    (report, report2) => {
      if (!report && !report2) return false;

      const examAlgorithm = getExamType(exam);
      const examSectionsKeys = Object.keys(exam).filter((key) =>
        key.includes("Sections")
      );
      const currentExamSectionsKey = examSectionsKeys.filter(
        (key) => exam[key].length > 0
      );
      if (currentExamSectionsKey.length !== 1) return false;
      if (
        exam[currentExamSectionsKey]?.[0]?.completionState ===
          sectionCompletionState.Invalid ||
        exam[currentExamSectionsKey]?.[1]?.completionState ===
          sectionCompletionState.Invalid
      )
        return false;
      if (
        (examAlgorithm === "D-15" && report?.state?.row2?.length > 0) ||
        (examAlgorithm === "D-15" && report2?.state?.row2?.length > 0)
      )
        return true;
      if (
        // examAlgorithm === "Pelli Robson" &&
        report?.state?.letters?.length > 0 ||
        report2?.state?.letters?.length > 0
      )
        return true;
      if (
        // examAlgorithm === "Tumbling E" &&
        report?.state?.directions?.length > 0 ||
        report2?.state?.directions?.length > 0
      )
        return true;
      // Visual Field || Esterman || Full120
      if (
        report?.state?.visualSpots?.length > 0 &&
        report2?.state?.visualSpots?.length > 0
      )
        return true;
      if (
        report?.state?.visualSpots?.length > 0 ||
        report2?.state?.visualSpots?.length > 0
      )
        return true;

      return false;
    },
    [exam]
  );

  useEffect(() => {
    setShouldDisplayReport(validateExamReports(examReport1, examReport2));
  }, [examReport1, examReport2, validateExamReports]);

  // initial data fetching
  useEffect(() => {
    const controller = new AbortController();
    initialFetch(controller.signal);
    return () => {
      controller.abort();
    };
  }, [initialFetch]);

  useEffect(() => {
    const duration = calculateDurationForDisplay(examReport1?.timing?.elapsed);
    setDuration1(duration);
  }, [examReport1?.timing?.elapsed, setDuration1]);
  useEffect(() => {
    const duration = calculateDurationForDisplay(examReport2?.timing?.elapsed);
    setDuration2(duration);
  }, [examReport2?.timing?.elapsed, setDuration2]);

  useEffect(() => {
    const getClinicById = async () => {
      let token = null;
      if (!isPdf) token = await getAccessTokenSilently();
      const clinic = await getClinics(token, clinicId);
      setClinicTimezone(clinic?.timezone ? clinic.timezone : null);
    };
    getClinicById();
  }, [clinicId, getAccessTokenSilently, isPdf]);

  const patientName = `${patient.firstName} ${patient.lastName}`;

  const comments1 = commentData1?.length
    ? commentData1.sort(
        (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
      )
    : [];
  const comments2 = commentData2?.length
    ? commentData2.sort(
        (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
      )
    : [];

  return {
    isLoading,
    patientName,
    patient,
    exam,
    examReport1,
    examReport2,
    eyeReport1,
    eyeReport2,
    comments1,
    comments2,
    sectionId1,
    sectionId2,
    refetchComment,
    durationStartTime1,
    durationStartTime2,
    duration1,
    duration2,
    shouldDisplayReport,
    clinicTimezone,
    newPatientId,
  };
};

export default ReportPdf;
