import { Box, Typography } from "@mui/material";
import Disclaimers from "./Disclaimers";
import { useContext } from "react";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";
import { useTranslation } from "react-i18next";

const PublicationInformation = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  return (
    <>
      <Box sx={{ ...sectionContainerStyle, padding: 3 }}>
        <Typography variant="h5" gutterBottom fontWeight={"bold"}>
          {t("operating_manual_publication_information")}
        </Typography>
        <Typography>
          {t("operating_manual_publication_information_content1")}
        </Typography>
        <br />
        <Typography>
          {t("operating_manual_publication_information_content2")}
        </Typography>
        <br />
        <Disclaimers />
        <Typography align="center">
          {t("operating_manual_copyright_content")}
        </Typography>
      </Box>
    </>
  );
};

export default PublicationInformation;
