import { Typography, Box, Grid } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Description1 from "../../../../../assets/images/operating-manual/4/description4.5.png";
import Description1French from "../../../../../assets/images/operating-manual/4/description4.5-french.png";
import Description2 from "../../../../../assets/images/operating-manual/4/4.5b.png";
import Description2French from "../../../../../assets/images/operating-manual/4/4.5b-french.png";
import Description3 from "../../../../../assets/images/operating-manual/4/4.5c.png";
import Description3French from "../../../../../assets/images/operating-manual/4/4.5c-french.png";
import Description4 from "../../../../../assets/images/operating-manual/4/4.5d.png";
import Description4French from "../../../../../assets/images/operating-manual/4/4.5d-french.png";
import Description5 from "../../../../../assets/images/operating-manual/4/4.5f.png";
import Description5French from "../../../../../assets/images/operating-manual/4/4.5f-french.png";
import Description6 from "../../../../../assets/images/operating-manual/4/4.5g.png";
import Description6French from "../../../../../assets/images/operating-manual/4/4.5g-french.png";
import Slider from "../../../../../assets/images/operating-manual/4/slider.png";
import PortalLink from "../../../../../assets/images/operating-manual/4/portal-link.png";
import AddExamButton from "../../../../../assets/images/operating-manual/4/add-exam.png";
import AddExamButtonFrench from "../../../../../assets/images/operating-manual/4/add-exam-french.png";
import LinkDeviceButton from "../../../../../assets/images/operating-manual/4/link-device.png";
import LinkDeviceButtonFrench from "../../../../../assets/images/operating-manual/4/link-device-french.png";
import CircularPlusIcon from "./CircularPlus";

const CreatingExam = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  const imgAttributes = [
    [AddExamButton, AddExamButtonFrench],
    [LinkDeviceButton, LinkDeviceButtonFrench],
    [Description4, Description4French],
    [Description1, Description1French],
    [Description6, Description6French],
    [Description5, Description5French],
    [Description2, Description2French],
    [Description3, Description3French],
  ];

  return (
    <Box id="creating-exam" sx={{ ...sectionContainerStyle, padding: 3 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
          color: "white",
          p: 1,
          pl: 4,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          4.5. {t("operating_manual_creating_exam_title")}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pl: 4,
          pr: 4,
          gap: 2,
        }}
      >
        <Grid container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {t("operating_manual_creating_exam_long_method_title")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              1. {t("operating_manual_creating_exam_description6")}
              <Box sx={{ display: "inline", p: 1 }}>
                <CircularPlusIcon />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={imgAttributes[3][t("operating_manual_image_index")]}
              alt="Description 1"
              sx={{ mt: 2, mb: 2, width: "100%" }}
            />
          </Grid>
        </Grid>

        <Grid container xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              2. {t("operating_manual_creating_exam_description7")}
              <Box
                component="img"
                src={Slider}
                alt="slider"
                sx={{ mt: 2, mb: 2, maxWidth: "8%" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={imgAttributes[6][t("operating_manual_image_index")]}
              alt="Description 2"
              sx={{ mt: 2, mb: 2, maxWidth: "50%" }}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            p: 2,
            mt: 2,
            backgroundColor: "grey.100",
            color: "text.secondary",
            borderRadius: 1,
          }}
        >
          {t("operating_manual_creating_exam_note3")}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          3. {t("operating_manual_creating_exam_description8")}
          <Box
            component="img"
            src={imgAttributes[0][t("operating_manual_image_index")]}
            alt="add-exam-button"
            sx={{ mt: 2, mb: 2, maxWidth: "8%" }}
          />
        </Box>

        <Box
          sx={{
            p: 2,
            mt: 2,
            backgroundColor: "grey.100",
            color: "text.secondary",
            borderRadius: 1,
          }}
        >
          {t("operating_manual_creating_exam_note3_1")}
          <u>{t("operating_manual_creating_exam_note3_2")}</u>
          {t("operating_manual_creating_exam_note3_3")}
          <u>{t("operating_manual_creating_exam_note3_4")}</u>
        </Box>

        <Grid container xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "inline-flex", alignItems: "center" }}>
              4. {t("operating_manual_creating_exam_description9")}
              <Box
                component="img"
                src={PortalLink}
                alt="portal-link"
                sx={{ mt: 2, mb: 2, maxWidth: "15%" }}
              />
            </Box>
            {t("operating_manual_creating_exam_description9_b")}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={imgAttributes[7][t("operating_manual_image_index")]}
              alt="Description 3"
              sx={{ mt: 2, mb: 2, maxWidth: "100%" }}
            />
          </Grid>
        </Grid>

        <Grid container xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "inline-flex", alignItems: "center" }}>
              5. {t("operating_manual_creating_exam_description10")}
              <Box
                component="img"
                src={imgAttributes[1][t("operating_manual_image_index")]}
                alt="link-device-button"
                sx={{ mt: 2, mb: 2, maxWidth: "16%" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={imgAttributes[2][t("operating_manual_image_index")]}
              alt="link-device-modal"
              sx={{ mt: 2, mb: 2, maxWidth: "100%" }}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            p: 2,
            mt: 2,
            backgroundColor: "grey.100",
            color: "text.secondary",
            borderRadius: 1,
            clear: "both",
            position: "relative",
          }}
        >
          <Box
            component="img"
            src={imgAttributes[5][t("operating_manual_image_index")]}
            alt="Note Image"
            sx={{
              float: "right",
              ml: 2,
              mb: 1,
              maxWidth: { xs: "40%", sm: "40%", md: "30%" },
              width: "100%",
              borderRadius: 1,
            }}
          />
          <Typography variant="body1">
            {t("operating_manual_creating_exam_note4")}
          </Typography>
        </Box>

        <Grid container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mt: 5 }}>
              {t("operating_manual_creating_exam_description12")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "grid", alignItems: "center" }}>
              1. {t("operating_manual_creating_exam_description13")}
              <Box
                component="img"
                src={imgAttributes[4][t("operating_manual_image_index")]}
                alt="link-device-button"
                sx={{ mt: 2, mb: 2, maxWidth: "50%" }}
              />
              <Link to="/operating-manual/instructions-for-use/configure-clinic-settings">
                {t("operating_manual_creating_exam_description14")}
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={imgAttributes[3][t("operating_manual_image_index")]}
              alt="link-device-modal"
              sx={{ mt: 2, mb: 2, maxWidth: "100%" }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreatingExam;
