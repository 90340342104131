import SharedConfirmation from "../SharedConfirmation";

const Auth0UserModal = ({
  openAuth0UserModal,
  handleAuth0Close,
  addAuth0User,
}) => {
  return (
    <SharedConfirmation
      open={openAuth0UserModal}
      onClose={handleAuth0Close}
      onConfirm={addAuth0User}
      onCancel={handleAuth0Close}
      title={"auth0_create_user_title"}
      description={"auth0_create_user_description"}
      confirmationButtonText={"button_confirm"}
      isAuth0={true}
    />
  );
};

export default Auth0UserModal;
