// Library methods
import { useContext } from "react";
// Components
import ReportInfo from "./ReportInfo";
import {
  AlgorithmContext,
  ExamTypeContext,
} from "../../../../contexts/ExamPropertyContext";
import { ReportDataContext } from "../../../../contexts/ReportDataContext";
import { ReportEachSectionContext } from "../../../../contexts/CommentContext";
import { getExamType } from "../../../../utils/examReportsHelper";
import EyeReportContent from "../../../../components/UI/EyeReportContent";
import VisualAcuityGraph from "../../../../components/UI/VisualAcuityGraph";

const EyeReport = () => {
  const { TYPE_VISUAL_ACUITY } = useContext(ExamTypeContext);
  const { landoltC, snellen } = useContext(AlgorithmContext);
  const { exam } = useContext(ReportDataContext);
  const { examReport } = useContext(ReportEachSectionContext);

  // algorithm version number
  const examAlgorithm = getExamType(exam);

  if (!examReport) return null;

  // build the report content
  return (
    <div className="retinalogik-section retinalogik-report">
      {examAlgorithm === TYPE_VISUAL_ACUITY && (
        <EyeReportContent
          ReportInfo={<ReportInfo />}
          ExamGraph={<VisualAcuityGraph examData={examReport} />}
        />
      )}
      {examAlgorithm === landoltC && (
        <EyeReportContent
          ReportInfo={<ReportInfo />}
          ExamGraph={
            <VisualAcuityGraph examData={examReport} isLandoltC={true} />
          }
        />
      )}
      {examAlgorithm === snellen && (
        <EyeReportContent
          ReportInfo={<ReportInfo />}
          ExamGraph={
            <VisualAcuityGraph examData={examReport} isSnellen={true} />
          }
        />
      )}
    </div>
  );
};

export default EyeReport;
