import {
  Grid,
  IconButton,
  Typography,
  ThemeProvider,
  createTheme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ValidatePDFContext } from "../../../contexts/ValidatePDFContext";
import { getExamAlgorithmTranslation } from "../../../utils/examReportsHelper";
import { ReportDataContext } from "../../../contexts/ReportDataContext";
import {
  CommentModalContext,
  ReportEachSectionContext,
} from "../../../contexts/CommentContext";
import useReportInfoFontSize from "../../../hooks/useReportInfoLetterSize";
import { ReportInfoEachSectionContext } from "../../../contexts/ReportInfoEachSectionContext";
import useClinic from "../../../hooks/useClinic";

const CustomTypography = ({ title, body, isBlack = false }) => {
  const { dynamicFontSize } = useReportInfoFontSize();
  const { hasFourColumns } = useContext(ReportInfoEachSectionContext);
  const { sectionId: firstSectionId, sectionId2 } =
    useContext(ReportDataContext);
  const { clinicSettings } = useClinic();
  const { forBackendPdf } = useContext(ValidatePDFContext);
  const theme = useTheme();
  const smallerThanMd = useMediaQuery(theme.breakpoints.down("md"));
  const smallerThanSm = useMediaQuery(theme.breakpoints.down("sm"));
  const textColor = useMemo(
    () => (isBlack ? "text-black" : "text-[#4471DB]"),
    [isBlack]
  );
  const memoized = useMemo(
    () => (
      <Typography
        variant="body1"
        noWrap
        mb={0.5}
        fontSize={
          (smallerThanMd && hasFourColumns) || smallerThanSm
            ? dynamicFontSize * 0.8
            : forBackendPdf &&
                clinicSettings?.monocularReportPdfLayoutOption === "Single" &&
                firstSectionId != null &&
                sectionId2 != null
              ? dynamicFontSize * 0.9
              : dynamicFontSize
        }
      >
        <b>{title}</b>: <span className={textColor}>{body}</span>
      </Typography>
    ),
    [
      smallerThanMd,
      hasFourColumns,
      smallerThanSm,
      forBackendPdf,
      clinicSettings?.monocularReportPdfLayoutOption,
      firstSectionId,
      sectionId2,
      dynamicFontSize,
      title,
      textColor,
      body,
    ]
  );
  return memoized;
};

const AddCommentButton = ({ isWiderThanSmallScreen, handleClickOpen }) => {
  const memoized = useMemo(
    () => (
      <IconButton
        aria-label="add"
        size={isWiderThanSmallScreen ? "medium" : "small"}
        sx={{
          color: "#202338",
          width: 34,
          height: 34,
          marginLeft: 1,
          "&:hover": {
            color: "white",
            backgroundColor: "#202338",
            borderColor: "#202338",
          },
        }}
        onClick={handleClickOpen}
      >
        <FontAwesomeIcon icon={faComment} />
      </IconButton>
    ),
    [handleClickOpen, isWiderThanSmallScreen]
  );
  return memoized;
};

const ReportInfoHeaderContent = ({
  isWiderThanSmallScreen,
  examTypeTranslation,
}) => {
  const { t } = useTranslation();
  const { dynamicFontSize } = useReportInfoFontSize();
  const { clinicSettings } = useClinic();
  const { sectionId: firstSectionId, sectionId2 } =
    useContext(ReportDataContext);
  const { forBackendPdf } = useContext(ValidatePDFContext);
  const { exam, patient } = useContext(ReportDataContext);
  const { setCommentModalSectionId } = useContext(CommentModalContext);
  const { sectionId } = useContext(ReportEachSectionContext);
  const handleClickOpen = () => {
    setCommentModalSectionId(sectionId);
  };

  const shouldReduceFontSizeInPdf =
    forBackendPdf &&
    clinicSettings?.monocularReportPdfLayoutOption === "Single" &&
    firstSectionId != null &&
    sectionId2 != null;

  const examTranslation = useMemo(() => {
    const title = getExamAlgorithmTranslation(exam);
    if (title === "word_acuity_tumbling") return t("word_tumbling");
    return t(title);
  }, [t, exam]);

  return (
    <Grid
      container
      item
      xs={10.5}
      sm={11}
      pt={isWiderThanSmallScreen ? 1 : 0}
      pb={isWiderThanSmallScreen ? 1 : 0}
    >
      <Grid item xs={12} sm={6.5} display="flex" alignItems="center">
        <Typography
          variant={isWiderThanSmallScreen ? "h6" : "body1"}
          noWrap
          fontWeight="bold"
          fontSize={shouldReduceFontSizeInPdf ? 18 : undefined}
        >
          {t("devices_table_name")}:{" "}
          {patient.firstName + " " + patient.lastName}
        </Typography>
        {!forBackendPdf && (
          <AddCommentButton
            isWiderThanSmallScreen={isWiderThanSmallScreen}
            handleClickOpen={handleClickOpen}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={5.5}
        display="flex"
        alignItems="center"
        justifyContent={isWiderThanSmallScreen ? "flex-end" : "flex-start"}
        textAlign={isWiderThanSmallScreen ? "right" : "left"}
      >
        <Typography
          variant="body1"
          fontSize={shouldReduceFontSizeInPdf ? 14 : dynamicFontSize}
          mr={1}
          noWrap
        >
          {(examTypeTranslation ?? "") + examTranslation}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ReportInfoShared = ({ data = [], gridSizeData = [] }) => {
  const theme = useTheme();
  const smallerThanSmcreen = useMediaQuery(theme.breakpoints.down("sm"));
  const bpTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1300,
        xl: 1700,
      },
    },
  });
  const memoized = useMemo(() => {
    return (
      <ThemeProvider theme={bpTheme}>
        {data.map((group, index) => (
          <Grid
            item
            xs={gridSizeData?.[index]?.xs}
            sm={gridSizeData?.[index]?.sm}
            md={gridSizeData?.[index]?.md}
            lg={gridSizeData?.[index]?.lg}
            xl={gridSizeData?.[index]?.xl}
            key={index}
            pl={(smallerThanSmcreen && index % 2 === 0) || !index ? 2 : 0}
          >
            {group.map((item, itemIndex) => (
              <CustomTypography
                key={itemIndex}
                title={item?.title}
                body={item?.body}
                isBlack={item?.isBlack}
              />
            ))}
          </Grid>
        ))}
      </ThemeProvider>
    );
  }, [bpTheme, data, gridSizeData, smallerThanSmcreen]);
  return memoized;
};

export { ReportInfoShared, AddCommentButton, ReportInfoHeaderContent };
