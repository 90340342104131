import { Box, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import Image1 from "../../../../../assets/images/operating-manual/3/patient-dashboard.png";
import Image2 from "../../../../../assets/images/operating-manual/3/dashboard.png";
import Image3 from "../../../../../assets/images/operating-manual/3/live-updates.png";
import Image4 from "../../../../../assets/images/operating-manual/3/live-update-open.png";
import VolumeSlider from "../../../../../assets/images/operating-manual/3/live-update/volume-slider.png";
import ControllerBeep from "../../../../../assets/images/operating-manual/3/live-update/controller-beep.png";
import Recenter from "../../../../../assets/images/operating-manual/3/live-update/calibrate.png";
import Close from "../../../../../assets/images/operating-manual/3/live-update/close.png";
import Info from "../../../../../assets/images/operating-manual/3/live-update/info.png";
import Stage from "../../../../../assets/images/operating-manual/3/live-update/stage.png";
import Progress from "../../../../../assets/images/operating-manual/3/live-update/progress.png";
import Gaze from "../../../../../assets/images/operating-manual/3/live-update/gaze.png";
import FP from "../../../../../assets/images/operating-manual/3/live-update/fp.png";
import FN from "../../../../../assets/images/operating-manual/3/live-update/fn.png";
import FL from "../../../../../assets/images/operating-manual/3/live-update/fl.png";
import LiveUpdateGrid from "../../../../../assets/images/operating-manual/3/live-update/grid.png";
import Play from "../../../../../assets/images/operating-manual/3/live-update/play.png";
import Restart from "../../../../../assets/images/operating-manual/3/live-update/restart.png";
import Delete from "../../../../../assets/images/operating-manual/3/live-update/delete.png";
import SkipTutorial from "../../../../../assets/images/operating-manual/3/live-update/skip-tutorial.png";
import VRTestMode from "../../../../../assets/images/operating-manual/3/live-update/vr-test-mode.png";
import VRScreen from "../../../../../assets/images/operating-manual/3/live-update/vr-screen.png";
import { useTranslation } from "react-i18next";

const PortalGuide = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  const { t } = useTranslation();
  const imgAttributes = [
    [
      { src: VolumeSlider, alt: "live-update" },
      { src: ControllerBeep, alt: "controller-beep" },
      { src: Recenter, alt: "recenter" },
      { src: Close, alt: "close" },
      { src: Info, alt: "info" },
      { src: Stage, alt: "stage" },
      { src: Progress, alt: "progress" },
      { src: Gaze, alt: "gaze" },
      { src: FP, alt: "fp" },
      { src: FN, alt: "fn" },
      { src: FL, alt: "fl" },
      { src: LiveUpdateGrid, alt: "live-update-grid" },
      { src: Play, alt: "play" },
      { src: Restart, alt: "restart" },
      { src: Delete, alt: "delete" },
      { src: SkipTutorial, alt: "skip-tutorial" },
      { src: VRTestMode, alt: "vr-test-mode" },
      { src: VRScreen, alt: "vr-screen" },
    ],
  ];

  return (
    <div id="portal-guide">
      <Box sx={{ ...sectionContainerStyle, padding: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background:
              "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
            color: "white",
            p: 1,
            pl: 4,
            borderRadius: 2,
            mb: 3,
            boxShadow: "0px 0.625rem 3rem rgba(0, 0, 0, 0.06)",
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            {t(`operating_manual_portal_sections_overview`)}
          </Typography>
        </Box>

        <div className="mx-auto max-w-4xl">
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            {/* Patient Page Section */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {t(`operating_manual_portal_patient_header`)}
              </Typography>
              <Box
                component="img"
                src={Image1}
                alt="Patient Dashboard"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  display: "block",
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                className="mx-auto mb-5 bg-gray-100 p-4 rounded-lg shadow-md"
              >
                {[...Array(4)].map((_, index) => (
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    key={index}
                    className="mb-4"
                  >
                    <Grid item xs={1}>
                      <Typography fontWeight="bold">{index + 1}</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography fontWeight="bold">
                        {t(
                          `operating_manual_portal_patient_view_header${index + 1}_header`
                        )}
                      </Typography>
                      <Typography>
                        {t(
                          `operating_manual_portal_patient_view_content${index + 1}_content`
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* Dashboard Page Section */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {t(`operating_manual_portal_dashboard_header`)}
              </Typography>
              <Box
                component="img"
                src={Image2}
                alt="Dashboard"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  display: "block",
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                className="mx-auto mb-5 bg-gray-100 p-4 rounded-lg shadow-md"
              >
                {[...Array(5)].map((_, index) => (
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    key={index}
                    className="mb-4"
                  >
                    <Grid item xs={1}>
                      <Typography fontWeight="bold">{index + 1}</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography fontWeight="bold">
                        {t(
                          `operating_manual_portal_dashboard_view_header${index + 1}_header`
                        )}
                      </Typography>
                      <Typography>
                        {t(
                          `operating_manual_portal_dashboard_view_content${index + 1}_content`
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* Live Update Page Section */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {t(`operating_manual_portal_live_update_header`)}
              </Typography>
              <p>
                <Typography>
                  {t(`operating_manual_portal_live_update_description1`)}
                  <img
                    src={Image4}
                    alt={"live-update-open"}
                    style={{ width: "3%" }}
                  />
                  {t(`operating_manual_portal_live_update_description1_2`)}
                </Typography>
              </p>
              <Box
                component="img"
                src={Image3}
                alt="Live Updates"
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  display: "block",
                  mt: 2,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                className="mx-auto mb-5 bg-gray-100 p-4 rounded-lg shadow-md"
              >
                {[...Array(18)].map((_, index) => (
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    key={index}
                    className="mb-4"
                  >
                    <Grid item xs={1}>
                      <Typography fontWeight="bold">{index + 1}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <img
                        src={imgAttributes[0][index].src}
                        alt={imgAttributes[0][index].alt}
                        style={{ width: "80%", objectFit: "cover" }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Typography fontWeight="bold">
                        {t(
                          `operating_manual_portal_live_update_table_header${index + 1}`
                        )}
                      </Typography>
                      <Typography>
                        {t(
                          `operating_manual_portal_live_update_table_content${index + 1}`
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Box>
    </div>
  );
};

export default PortalGuide;
