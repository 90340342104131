import { Typography, Box } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import comment from "../../../../../assets/images/operating-manual/4/comment.png";
import download from "../../../../../assets/images/operating-manual/4/download.png";
import search from "../../../../../assets/images/operating-manual/4/search.png";
import link from "../../../../../assets/images/operating-manual/4/link.png";
import Image1 from "../../../../../assets/images/operating-manual/4/4.7a.png";
import Image1French from "../../../../../assets/images/operating-manual/4/4.7a-french.png";
import Image2 from "../../../../../assets/images/operating-manual/4/4.7b.png";
import Image2French from "../../../../../assets/images/operating-manual/4/4.7b-french.png";

const ViewingResults = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  const imgAttributes = [
    [Image1, Image1French],
    [Image2, Image2French],
  ];

  return (
    <Box id="viewing-results" sx={{ ...sectionContainerStyle, padding: 3 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
          color: "white",
          p: 1,
          pl: 4,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" fontWeight="600">
          {`4.7. ${t("operating_manual_viewing_results_title")}`}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pl: 4,
          pr: 4,
          gap: 2,
        }}
      >
        <Box sx={{ ...sectionContainerStyle, mt: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <Typography sx={{ color: "text.primary", flex: 1 }}>
              {`1. ${t("operating_manual_viewing_results_description1")}`}
              <Box
                component="img"
                src={link}
                alt="Description Icon"
                sx={{
                  width: "5%",
                  verticalAlign: "middle",
                  mx: 0.5,
                  display: "inline-block",
                }}
              />
              {t("operating_manual_viewing_results_description1b")}
            </Typography>

            <Box
              component="img"
              src={imgAttributes[0][t("operating_manual_image_index")]}
              alt="Description Image"
              sx={{
                width: "50%",
                height: "auto",
                ml: 2,
                float: "right",
                borderRadius: 1,
              }}
            />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <Typography sx={{ color: "text.primary", flex: 1 }}>
              {`2. ${t("operating_manual_viewing_results_description2")}`}
              <Box
                component="img"
                src={search}
                alt="Description Icon"
                sx={{
                  width: "4%",
                  verticalAlign: "middle",
                  mx: 0.5,
                  display: "inline-block",
                }}
              />
              {t("operating_manual_viewing_results_description2b")}
            </Typography>

            <Box
              component="img"
              src={imgAttributes[1][t("operating_manual_image_index")]}
              alt="Description Image"
              sx={{
                width: "40%",
                height: "auto",
                ml: 2,
                float: "right",
                borderRadius: 1,
              }}
            />
          </Box>

          <Typography sx={{ mt: 1, color: "text.primary" }}>
            3.{" "}
            <span style={{ color: "#FB923C", fontWeight: "bold" }}>
              {t("operating_manual_viewing_results_description3_1")}{" "}
            </span>
            {t("operating_manual_viewing_results_description3_2")}{" "}
            <Box
              component="img"
              src={comment}
              alt="Description Image"
              sx={{
                width: "3%",
                verticalAlign: "middle",
                mx: 0.5,
                display: "inline-block",
              }}
            />{" "}
            {t("operating_manual_viewing_results_description3_3")}
          </Typography>

          <Typography mb={2}>
            {`4. ${t("operating_manual_viewing_results_description4")}`}
            <Box
              component="img"
              src={download}
              alt="Description Image"
              sx={{
                width: "10%",
                verticalAlign: "middle",
                mx: 0.5,
                display: "inline-block",
              }}
            />{" "}
            {t("operating_manual_viewing_results_description4_1")}
          </Typography>
        </Box>

        <Box
          sx={{
            backgroundColor: "grey.100",
            color: "text.secondary",
            p: 2,
            mt: 3,
            borderRadius: 2,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
          }}
        >
          {t("operating_manual_viewing_results_note")}
        </Box>
      </Box>
    </Box>
  );
};

export default ViewingResults;
