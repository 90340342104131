// Library methods
import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";

// Components
import Box from "@mui/material/Box";
import App from "./components/Routing/App";
import Loader from "./components/UI/Loader";

// Providers
import { ClinicProvider } from "./contexts/ClinicContext";
import Auth0ProviderWrapper from "./auth/Auth0ProviderWrapper";
import ThemeProviderWrapper from "./styles/ThemeProviderWrapper";
import { WindowDimensionsProvider } from "./contexts/WindowSizeContext";

// Configs
import reportWebVitals from "./config/reportWebVitals";
import "./i18n";

// Styles
import { PageContainerWrapper } from "./styles/muiStylesHelper";
import "./styles/tailwind/output.css";

// Utilities
import { isProduction } from "./utils/envHelper";
import { ExamTypeProvider } from "./contexts/ExamPropertyContext";
import { ContrastImageProvider } from "./contexts/ContrastImageContext";
import { DeviceAppVersionProvider } from "./contexts/DeviceContext";
import { ExamTransferProvider } from "./contexts/ExamTransferContext";
import { ToastProvider } from "./contexts/ToastContext";

// Replace console.* for disabling log on production
if (isProduction) {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}
const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWrapper>
        <ToastProvider>
          <ClinicProvider>
            <DeviceAppVersionProvider>
              <ExamTransferProvider>
                <Suspense
                  fallback={
                    <Box sx={PageContainerWrapper()}>
                      <Loader />
                    </Box>
                  }
                >
                  <ThemeProviderWrapper>
                    <WindowDimensionsProvider>
                      <ExamTypeProvider>
                        <ContrastImageProvider>
                          <App />
                        </ContrastImageProvider>
                      </ExamTypeProvider>
                    </WindowDimensionsProvider>
                  </ThemeProviderWrapper>
                </Suspense>
              </ExamTransferProvider>
            </DeviceAppVersionProvider>
          </ClinicProvider>
        </ToastProvider>
      </Auth0ProviderWrapper>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
