import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

const WarningItem = ({ number }) => {
  const { t } = useTranslation();

  // Special case for item 10, with sub-items
  if (number === 10) {
    return (
      <li>
        {t(`operating_manual_warning_precaution_warning${number}`)}
        <ul>
          {[...Array(4)].map((_, subIndex) => (
            <li key={subIndex}>
              {t(
                `operating_manual_warning_precaution_warning${number}_${subIndex + 1}`
              )}
            </li>
          ))}
        </ul>
      </li>
    );
  }

  return <li>{t(`operating_manual_warning_precaution_warning${number}`)}</li>;
};

const WarningList = () => (
  <ol mt={5}>
    {[...Array(20)].map((_, index) => (
      <WarningItem key={index} number={index + 1} />
    ))}
  </ol>
);

const WarningsPrecautions = () => {
  const { t } = useTranslation();

  return (
    <Box
      id="warnings-and-precautions"
      className="border border-red-600 bg-red-50 p-4 rounded-lg"
    >
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        {t("operating_manual_warning_precaution_title")}
      </Typography>
      <Typography>
        {t("operating_manual_warning_precaution_description")}
      </Typography>

      <Box display="flex" mt={3}>
        <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500" />{" "}
        <Typography variant="h5" fontWeight="bold" ml={1}>
          {t("operating_manual_warning_precaution_header")}
        </Typography>
      </Box>

      <WarningList />
    </Box>
  );
};

export default WarningsPrecautions;
