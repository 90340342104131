import { useAuth0 } from "@auth0/auth0-react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { transferExam } from "../services/Exam";
import useClinic from "../hooks/useClinic";
import { ToastContext } from "./ToastContext";
import { useTranslation } from "react-i18next";

const ExamTransferContext = createContext({
  openExamTransferModal: false,
  transferPatient: null,
  basePatientId: null,
  //   setOpenExamTransferModal: () => {},
  setTransferPatient: () => {},
  setBasePatientId: () => {},
  handleExamTransferClick: () => {},
  handleExamTransferClose: () => {},
  handleExamTransfer: () => {},
});

const ExamTransferProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const { setToast } = useContext(ToastContext);
  const { clinicId } = useClinic();
  const [openExamTransferModal, setOpenExamTransferModal] = useState(false);
  const [selectedExamTransfer, setSelectedExamTransfer] = useState(null);
  const [transferPatient, setTransferPatient] = useState(null);
  const [basePatientId, setBasePatientId] = useState("");

  const handleExamTransferClick = useCallback((selected, patientId) => {
    setOpenExamTransferModal(true);
    setSelectedExamTransfer(selected);
    setBasePatientId(patientId);
  }, []);

  const handleExamTransferClose = useCallback((event, reason) => {
    if (reason !== "backdropClick") {
      setOpenExamTransferModal(false);
    }
  }, []);

  const handleExamTransfer = useCallback(async () => {
    setOpenExamTransferModal(false);
    try {
      const token = await getAccessTokenSilently();
      await transferExam(
        token,
        clinicId,
        basePatientId,
        selectedExamTransfer?.id,
        transferPatient?.id
      );
      setToast({
        success: true,
        message: t("exam_transfer_modal_success"),
      });
    } catch (e) {
      setToast({
        success: false,
        message: t("exam_transfer_modal_failure"),
      });
    }
  }, [
    transferPatient,
    basePatientId,
    selectedExamTransfer,
    getAccessTokenSilently,
    clinicId,
    t,
    setToast,
  ]);

  useEffect(() => {
    if (!openExamTransferModal) {
      setSelectedExamTransfer(null);
      setTransferPatient(null);
      setBasePatientId("");
    }
  }, [openExamTransferModal]);
  return (
    <ExamTransferContext.Provider
      value={{
        openExamTransferModal: openExamTransferModal,
        transferPatient: transferPatient,
        basePatientId: basePatientId,
        setTransferPatient: setTransferPatient,
        setBasePatientId: setBasePatientId,
        handleExamTransfer: handleExamTransfer,
        handleExamTransferClick: handleExamTransferClick,
        handleExamTransferClose: handleExamTransferClose,
      }}
    >
      {children}
    </ExamTransferContext.Provider>
  );
};

export { ExamTransferContext, ExamTransferProvider };
