// Library Methods
import { useTranslation } from "react-i18next";
import React, { useCallback, useContext, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

// MUI Components
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";

// Utilities
import {
  ModalCustomOutlined,
  ModalCustomSwitch,
} from "../../../styles/muiStylesHelper";
import { EyeContext } from "../../../contexts/ExamPropertyContext";
import { FixationLossMethodOptions } from "../../../utils/examReportsHelper";
import { availableLanguages } from "../../../i18n/languages";

const CustomButton = ({ value, currentValue, setFunction, label }) => {
  const isSelected = currentValue.toLowerCase() === value.toLowerCase();

  const variant = isSelected ? "text" : "outlined";
  const modalType = isSelected ? "fill" : "outlined";

  return (
    <Button
      variant={variant}
      sx={{
        ...ModalCustomOutlined(modalType),
        marginTop: { xs: "5px", sm: 0 },
        padding: "2.5px",
        whiteSpace: "normal",
        lineHeight: "1.4",
      }}
      onClick={() => setFunction(value)}
    >
      {label}
    </Button>
  );
};

const SectionHeader = ({ title, tooltip, t }) => {
  return (
    <Grid
      item
      display="flex"
      justifyContent={"space-between"}
      alignItems={"center"}
      marginBottom={".5rem"}
      maxWidth={"50%"}
    >
      <Typography
        color={"#ACA8A8"}
        fontSize={"16px"}
        fontWeight={"500"}
        marginRight={".5rem"}
      >
        {t(title)}
      </Typography>
      <Tooltip title={t(tooltip)}>
        <FontAwesomeIcon
          icon={faInfoCircle}
          style={{
            color: "#ACA8A8",
          }}
        />
      </Tooltip>
    </Grid>
  );
};

const VisualFieldCard = ({
  examDefaultValue = {
    examAlgorithm: "FAST_THRESHOLD",
    leftCheckBox: true,
    rightCheckBox: true,
    requiresTutorial: true,
    autoPause: true,
    encouragement: true,
    visualGrid: "G_24_2",
    stimulusSizeType: "III",
    calibration: true,
    fixationType: {
      color: null,
      fixationPointShapeType: "sphere",
      fixationLossMethod: FixationLossMethodOptions.EyeTracking,
    },
    rightFirst: true,
  },
  setExamDefaultValue,
}) => {
  // internationalization
  const { t, i18n } = useTranslation();

  const { eyeLeft, eyeRight, eyeBoth } = useContext(EyeContext);

  const isEn = useMemo(() => {
    const en = availableLanguages[0];
    const language = i18n.language;
    return language.includes(en);
  }, [i18n.language]);

  const handleEyeChange = (value) => {
    if (value === eyeBoth) {
      setExamDefaultValue((prev) => ({
        ...prev,
        leftCheckBox: true,
        rightCheckBox: true,
      }));
    } else if (value === eyeLeft) {
      setExamDefaultValue((prev) => ({
        ...prev,
        leftCheckBox: true,
        rightCheckBox: false,
      }));
    } else if (value === eyeRight) {
      setExamDefaultValue((prev) => ({
        ...prev,
        leftCheckBox: false,
        rightCheckBox: true,
      }));
    } else {
      setExamDefaultValue((prev) => ({
        ...prev,
        leftCheckBox: false,
        rightCheckBox: false,
      }));
    }
  };

  const handleChangeSectionOrderToggle = useCallback(() => {
    setExamDefaultValue((prev) => {
      const res = { ...prev };
      res.rightFirst = !prev?.rightFirst ?? true;
      return res;
    });
  }, [setExamDefaultValue]);

  const EyeOption = ({ eye, checkBox1, checkBox2, handleEyeChange }) => {
    const checkedCondition =
      eye === eyeBoth
        ? checkBox1 && checkBox2
        : eye === eyeLeft
          ? checkBox1 && !checkBox2
          : !checkBox1 && checkBox2;

    return (
      <Grid
        item
        textAlign={"center"}
        marginBottom={eye !== eyeBoth ? ".5rem" : undefined}
      >
        <Radio
          checked={checkedCondition}
          onChange={() => handleEyeChange(eye)}
          value="a"
          name="radio-buttons"
          inputProps={{ "aria-label": eye }}
          sx={{
            "&.MuiButtonBase-root.MuiRadio-root": {
              color: "#E2772E",
            },
          }}
        />
        <Typography fontSize={"10px"} fontWeight={"600"} color={"#ACA8A8"}>
          {t(`word_${eye.toLowerCase()}`)}
        </Typography>
      </Grid>
    );
  };

  const ToggleGrid = ({ title, tooltip, checked, onChange, mb = ".5rem" }) => (
    <Grid
      item
      display="flex"
      justifyContent={"space-between"}
      alignItems={"center"}
      marginBottom={mb}
    >
      <SectionHeader title={title} tooltip={tooltip} t={t} />
      <Grid item display="flex" justifyContent={"space-between"}>
        <FormControlLabel
          sx={{ marginBottom: 0 }}
          control={
            <Switch
              checked={checked}
              onChange={onChange}
              sx={{ ...ModalCustomSwitch() }}
            />
          }
          label=""
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      display="flex"
      width="350px"
      height={{ xs: isEn ? "600px" : "620px", sm: isEn ? "570px" : "590px" }}
      flexDirection="column"
      justifyContent={"space-between"}
      my="1rem"
      padding="1rem"
      borderRadius={"10px"}
      boxShadow="-20px 0px 25px rgba(0, 0, 0, 0.05)"
      border-radius="16px"
      border="1px solid #CFCFCF"
      backgroundColor={"#282C3C"}
    >
      <Grid>
        {/* method */}
        <Grid
          item
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={".5rem"}
        >
          <SectionHeader title="word_method" tooltip="method_tooltip" t={t} />
          <Grid
            container
            item
            display="flex"
            flexDirection="column"
            marginBottom={".5rem"}
          >
            <Grid
              display="flex"
              flexDirection="row"
              justifyContent={"right"}
              marginBottom={0.5}
            >
              <CustomButton
                value="SCREEN_FAST"
                currentValue={examDefaultValue.examAlgorithm}
                setFunction={(val) =>
                  setExamDefaultValue((prev) => ({
                    ...prev,
                    examAlgorithm: val,
                  }))
                }
                label={t("word_screen_fast")}
              />
              <CustomButton
                value="FAST_THRESHOLD"
                currentValue={examDefaultValue.examAlgorithm}
                setFunction={(val) =>
                  setExamDefaultValue((prev) => ({
                    ...prev,
                    examAlgorithm: val,
                  }))
                }
                label={t("word_fast")}
              />
            </Grid>
            <Grid display="flex" flexDirection="row" justifyContent={"right"}>
              <CustomButton
                value="STANDARD_THRESHOLD"
                currentValue={examDefaultValue.examAlgorithm}
                setFunction={(val) =>
                  setExamDefaultValue((prev) => ({
                    ...prev,
                    examAlgorithm: val,
                  }))
                }
                label={t("word_standard")}
              />
              <CustomButton
                value="FULL_THRESHOLD"
                currentValue={examDefaultValue.examAlgorithm}
                setFunction={(val) =>
                  setExamDefaultValue((prev) => ({
                    ...prev,
                    examAlgorithm: val,
                  }))
                }
                label={t("word_full")}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* grid */}
        <Grid
          item
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={".5rem"}
        >
          <SectionHeader title="word_grid" tooltip="grid_size_tooltip" t={t} />
          <Grid
            container
            item
            display="flex"
            flexDirection={"column"}
            marginBottom={".5rem"}
          >
            <Grid
              display="flex"
              flexDirection="row"
              marginBottom={0.5}
              justifyContent={"right"}
            >
              <CustomButton
                value="G_24_2"
                currentValue={examDefaultValue.visualGrid}
                setFunction={(val) =>
                  setExamDefaultValue((prev) => ({
                    ...prev,
                    visualGrid: val,
                  }))
                }
                label="24-2"
              />
              <CustomButton
                value="G_24_2_C"
                currentValue={examDefaultValue.visualGrid}
                setFunction={(val) =>
                  setExamDefaultValue((prev) => ({
                    ...prev,
                    visualGrid: val,
                  }))
                }
                label="24-2C"
              />
            </Grid>
            <Grid display="flex" flexDirection="row" justifyContent={"right"}>
              <CustomButton
                value="G_30_2"
                currentValue={examDefaultValue.visualGrid}
                setFunction={(val) =>
                  setExamDefaultValue((prev) => ({
                    ...prev,
                    visualGrid: val,
                  }))
                }
                label="30-2"
              />
              <CustomButton
                value="G_10_2"
                currentValue={examDefaultValue.visualGrid}
                setFunction={(val) =>
                  setExamDefaultValue((prev) => ({
                    ...prev,
                    visualGrid: val,
                  }))
                }
                label="10-2"
              />
            </Grid>
          </Grid>
        </Grid>

        {/* stimulus */}
        <Grid
          item
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={".5rem"}
        >
          <SectionHeader
            title="report_stimulus_size"
            tooltip="stimulus_size_tooltip"
            t={t}
          />

          <Grid
            item
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            marginBottom={".5rem"}
            flexWrap={"wrap"}
          >
            <CustomButton
              value="III"
              currentValue={examDefaultValue.stimulusSizeType}
              setFunction={(val) =>
                setExamDefaultValue((prev) => ({
                  ...prev,
                  stimulusSizeType: val,
                }))
              }
              label="III"
            />
            <CustomButton
              value="IV"
              currentValue={examDefaultValue.stimulusSizeType}
              setFunction={(val) =>
                setExamDefaultValue((prev) => ({
                  ...prev,
                  stimulusSizeType: val,
                }))
              }
              label="IV"
            />
            <CustomButton
              value="V"
              currentValue={examDefaultValue.stimulusSizeType}
              setFunction={(val) =>
                setExamDefaultValue((prev) => ({
                  ...prev,
                  stimulusSizeType: val,
                }))
              }
              label="V"
            />
          </Grid>
        </Grid>

        {/* Fixation */}
        <Grid
          item
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <SectionHeader title="fixation" tooltip="fixation_tooltip" t={t} />

          <Grid
            item
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            marginBottom={".5rem"}
            flexWrap={"wrap"}
          >
            <CustomButton
              value="sphere"
              currentValue={
                examDefaultValue?.fixationType?.fixationPointShapeType
              }
              setFunction={(value) =>
                setExamDefaultValue((prev) => ({
                  ...prev,
                  fixationType: {
                    ...prev?.fixationType,
                    fixationPointShapeType: value,
                  },
                }))
              }
              label={t("exams_modal_sphere")}
            />
            <CustomButton
              value="diamond"
              currentValue={
                examDefaultValue?.fixationType?.fixationPointShapeType
              }
              setFunction={(value) =>
                setExamDefaultValue((prev) => ({
                  ...prev,
                  fixationType: {
                    ...prev?.fixationType,
                    fixationPointShapeType: value,
                  },
                }))
              }
              label={t("diamond")}
            />
          </Grid>
        </Grid>

        <Grid
          item
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <SectionHeader
            title="fixation_loss_method"
            tooltip="fixation_loss_method_tooltip"
            t={t}
          />

          <Grid
            item
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            marginBottom={".5rem"}
            flexWrap={"wrap"}
          >
            <CustomButton
              value={FixationLossMethodOptions.EyeTracking}
              currentValue={examDefaultValue.fixationType.fixationLossMethod}
              setFunction={(value) =>
                setExamDefaultValue((prev) => ({
                  ...prev,
                  fixationType: {
                    ...prev?.fixationType,
                    fixationLossMethod: value,
                  },
                }))
              }
              label={t("eye_tracking")}
            />
            <CustomButton
              value={FixationLossMethodOptions.BlindSpot}
              currentValue={examDefaultValue.fixationType.fixationLossMethod}
              setFunction={(value) =>
                setExamDefaultValue((prev) => ({
                  ...prev,
                  fixationType: {
                    ...prev?.fixationType,
                    fixationLossMethod: value,
                  },
                }))
              }
              label={t("word_blind_spot")}
            />
          </Grid>
        </Grid>

        {/* eye */}
        <Grid
          item
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          pr={
            examDefaultValue.leftCheckBox && examDefaultValue.rightCheckBox
              ? 2
              : 0
          }
        >
          <SectionHeader title="word_eye" tooltip="eye_test_tooltip" t={t} />
          <Grid item display="flex" justifyContent={"space-between"}>
            <EyeOption
              eye={eyeBoth}
              checkBox1={examDefaultValue.leftCheckBox}
              checkBox2={examDefaultValue.rightCheckBox}
              handleEyeChange={handleEyeChange}
              t={t}
            />
            <EyeOption
              eye={eyeLeft}
              checkBox1={examDefaultValue.leftCheckBox}
              checkBox2={examDefaultValue.rightCheckBox}
              handleEyeChange={handleEyeChange}
              t={t}
            />
            <EyeOption
              eye={eyeRight}
              checkBox1={examDefaultValue.leftCheckBox}
              checkBox2={examDefaultValue.rightCheckBox}
              handleEyeChange={handleEyeChange}
              t={t}
            />
          </Grid>
          {examDefaultValue.leftCheckBox && examDefaultValue.rightCheckBox && (
            <Grid
              item
              display="flex"
              flexDirection={"column"}
              justifyContent={"space-between"}
              alignItems={"center"}
              marginBottom={".5rem"}
              mr={i18n.language === "en" ? 1 : 0}
            >
              <Grid
                item
                display="flex"
                justifyContent={"space-between"}
                width={30}
                mb={0.5}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={examDefaultValue?.rightFirst}
                      onChange={handleChangeSectionOrderToggle}
                      sx={{ ...ModalCustomSwitch() }}
                    />
                  }
                  label=""
                />
              </Grid>
              <Grid fontSize={"10px"} fontWeight={"600"} color={"#FFF"}>
                {t("exams_modal_right_first")}
              </Grid>
            </Grid>
          )}
        </Grid>

        {/* auto-pause */}
        <ToggleGrid
          title="auto_pause"
          tooltip="auto_pause_tooltip"
          checked={examDefaultValue.autoPause ?? false}
          onChange={(e) =>
            setExamDefaultValue((prev) => ({
              ...prev,
              autoPause: e.target.checked,
            }))
          }
          mb={0}
        />

        {/* encouragement */}
        <ToggleGrid
          title="encouragement"
          tooltip="encouragement_tooltip"
          checked={examDefaultValue.encouragement ?? false}
          onChange={(e) =>
            setExamDefaultValue((prev) => ({
              ...prev,
              encouragement: e.target.checked,
            }))
          }
          mb={0}
        />

        {/* Calibration */}
        <ToggleGrid
          title="calibration"
          tooltip="calibration_tooltip"
          checked={examDefaultValue.calibration ?? false}
          onChange={(e) =>
            setExamDefaultValue((prev) => ({
              ...prev,
              calibration: e.target.checked,
            }))
          }
          mb={0}
        />

        {/* tutorial */}
        <ToggleGrid
          title="exams_table_expand_tutorial"
          tooltip="tutorial_tooltip"
          checked={examDefaultValue.requiresTutorial ?? false}
          onChange={(e) =>
            setExamDefaultValue((prev) => ({
              ...prev,
              requiresTutorial: e.target.checked,
            }))
          }
        />
      </Grid>
    </Grid>
  );
};

export default VisualFieldCard;
