// Library methods
import {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { throttle } from "lodash";

// MUI Components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Components
import AddUserModal from "./AddUserModal";
import DeleteUserModal from "./DeleteUserModal";
import UsersCard from "./UsersCard";
import ClinicInfoSection from "./ClinicInfoSection";
import SnackbarMessage from "../../../components/UI/SnackbarMessage";
import Loader from "../../../components/UI/Loader";
import PaginationWrapper from "../../../components/UI/PaginationWrapper";
import PageIntro from "../../../components/UI/PageIntro";
import ListHeader from "../../../components/UI/ListHeader";

// Utilities
import {
  deleteClinicUsers,
  addClinicUsers,
  addAuth0UserToClinic,
} from "../../../services/Clinic";
import { getComparator } from "../../../utils/tableHelper";
import { itemsRendering, pagesCount } from "../../../utils/paginationHelper";
import { useWindowDimensions } from "../../../contexts/WindowSizeContext";
import LayoutHeightContext from "../../../contexts/LayoutHeight";
import Auth0UserModal from "../../../components/UI/Auth0UserModal";
import { ToastContext } from "../../../contexts/ToastContext";

const UsersList = ({
  rows,
  setRows,
  clinic,
  clinicName,
  clinicId,
  isLoading,
}) => {
  // internationalization
  const { t } = useTranslation();

  // screen height
  const { height } = useWindowDimensions();

  // auth0
  const { getAccessTokenSilently } = useAuth0();

  // toast
  const { toast, setToast } = useContext(ToastContext);

  // states init
  const [order, setOrder] = useState("asc");
  const [orderByOptions, setOrderByOptions] = useState(["user"]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);

  // form field states init
  const [userEmail, setUserEmail] = useState("");

  // state for add and delete patients modals
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [openAuth0UserModal, setOpenAuth0UserModal] = useState(false);

  // clinic add toast states
  const [successToast, setSuccessToast] = useState(false);
  const [failureToast, setFailureToast] = useState(false);

  // clinic delete toast states
  const [deleteSuccessToast, setDeleteSuccessToast] = useState(false);
  const [deleteFailureToast, setDeleteFailureToast] = useState(false);

  // state for the search bar
  const [searchTerm, setSearchTerm] = useState("");

  const [maxNumOfRows, setMaxNumOfRows] = useState(1);
  const usersCardRef = useRef(null);
  const [usersCardHeight, setUsersCardHeight] = useState(0);

  const { navbarHeight, footerHeight } = useContext(LayoutHeightContext);
  const [isRefSet, setIsRefSet] = useState(false);

  // filter change
  const onFilterChange = (event) => {
    if (page > 1) setPage(1);
    setSearchTerm(event.target.value);
  };

  const showAuth0Button = useMemo(() => selected?.length, [selected?.length]);

  // add a clinic
  const insertUser = useCallback(async () => {
    const newRow = { email: userEmail };

    // close modal
    setAddModal(false);

    // add to api
    try {
      // checks if the email contains @ and .
      if (!userEmail.includes("@") || !userEmail.includes("."))
        throw new Error();

      // get token
      const token = await getAccessTokenSilently();
      await addClinicUsers(token, clinicId, newRow);

      // get id generated from api
      const newRows = [...rows, { id: rows.length, user: userEmail }];
      setRows(newRows);
      setSuccessToast(true);

      // success toast
      setSuccessToast(true);
    } catch (error) {
      // failure toast
      setFailureToast(true);
    }

    // clear form
    setUserEmail("");
  }, [clinicId, getAccessTokenSilently, rows, setRows, userEmail]);

  // cancel add patient modal
  const cancelAdd = () => {
    // close modal
    setAddModal(false);

    // clear form
    setUserEmail("");
  };

  // delete patients selected
  const deleteUsers = async () => {
    // close delete Modal
    setDeleteModal(false);

    const deletePromises = selected.map(async (index) => {
      const token = await getAccessTokenSilently();
      await deleteClinicUsers(token, clinicId, rows[index].user);
    });

    try {
      await Promise.all(deletePromises);
      setDeleteSuccessToast(true);
    } catch (error) {
      setDeleteFailureToast(true);
    }

    const newRows = rows.filter((u) => !selected.includes(u.id));
    setRows(newRows);

    // success toast
    setDeleteSuccessToast(true);

    // clear selected
    setSelected([]);
  };

  const addAuth0User = useCallback(async () => {
    setOpenAuth0UserModal(false);
    setSelected([]);
    try {
      const token = await getAccessTokenSilently();
      const promises = selected.map((index) =>
        addAuth0UserToClinic(token, clinicId, rows[index].user)
      );
      await Promise.all(promises);
      setToast(() => ({
        success: true,
        message: t("auth0_create_user_success"),
      }));
    } catch (e) {
      setToast(() => ({
        success: false,
        message: t("auth0_create_user_failure"),
      }));
    }
  }, [getAccessTokenSilently, selected, setToast, clinicId, rows, t]);

  // handle sort Request
  const handleRequestSort = (event, property) => {
    const isAsc =
      orderByOptions.length &&
      orderByOptions[0] === property[0] &&
      order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderByOptions(property);
  };
  // handles select all
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredRows.map((n, i) => i);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // handle checkbox clicks
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const auth0ButtonOnClick = useCallback(() => setOpenAuth0UserModal(true), []);

  // handle on close of add clinic modal
  const handleAddClose = useCallback((event, reason) => {
    if (reason !== "backdropClick") {
      setAddModal(false);
    }
  }, []);

  // handle on close of delete clinic modal
  const handleDeleteClose = useCallback((event, reason) => {
    if (reason !== "backdropClick") {
      setDeleteModal(false);
    }
  }, []);

  const handleAuth0Close = useCallback((event, reason) => {
    if (reason !== "backdropClick") {
      setOpenAuth0UserModal(false);
    }
  }, []);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // head rows
  const headCells = [
    {
      id: "name",
      label: t("user_name"),
      gridSize: 4.5,
      orderUsing: ["user"],
    },
    {
      id: "email",
      label: t("users_table_email"),
      gridSize: 5.5,
      orderUsing: ["user"],
    },
  ];

  // Filtered rows
  const filteredRows = rows
    .slice()
    .filter((row) => {
      if (
        searchTerm.length &&
        row.user.toLowerCase().indexOf(searchTerm.toLowerCase()) < 0
      )
        return false;
      return true;
    })
    .sort(getComparator(order, orderByOptions));

  // pagination change
  const onPaginationChange = (event, value) => {
    setPage(value);
  };

  const rowsToRender = itemsRendering(filteredRows, page, 4);

  //  calculate the number of rendered rows
  useEffect(() => {
    if (usersCardRef.current) {
      const throttledFunc = throttle(() => {
        setUsersCardHeight(usersCardRef.current.offsetHeight);
      }, 1000);
      throttledFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefSet, usersCardRef.current, usersCardHeight]);
  // calculate the number of rendered rows
  useEffect(() => {
    if (height && usersCardHeight) {
      // 32: padding of the row *2
      setMaxNumOfRows(
        Math.floor(
          (height - navbarHeight - footerHeight - usersCardHeight * 3) /
            (usersCardHeight + 32)
        )
      );
      const pageCount = pagesCount(filteredRows, 4);
      if (pageCount < page) setPage(pageCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxNumOfRows, page, usersCardHeight, height]);

  useEffect(() => {
    setPage(1);
    setSelected([]);
  }, [clinic]);

  const handleReference = (val) => {
    setIsRefSet(val);
  };

  const ListElement = () =>
    isLoading ? (
      <Loader containerHeight="40vh" />
    ) : (
      <Grid container mt={3}>
        <Grid item xs={12}>
          <ListHeader
            headCells={headCells}
            numSelected={selected.length}
            order={order}
            orderBy={orderByOptions?.[0] ?? "user"}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={filteredRows.length}
            checkBoxGrid={2}
          />
          {rowsToRender.length > 0 ? (
            <Box mt={1}>
              {rowsToRender.map((row, i) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <UsersCard
                    key={row.id}
                    row={row}
                    headCells={headCells}
                    isSelected={isItemSelected}
                    handleCheckboxClick={handleClick}
                    handleReference={handleReference}
                    ref={i === 0 ? usersCardRef : null}
                  />
                );
              })}
            </Box>
          ) : (
            <Box my={8} display="flex" justifyContent="center">
              <Typography
                noWrap
                variant="body1"
                sx={{ textTransform: "capitalize" }}
                color="text.secondary"
              >
                {t("word_no_users")}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    );

  // if there is no selected clinic
  if (!clinicId) {
    return (
      <>
        <PageIntro
          pageTitle="Users"
          showAddButton={false}
          disableSearchButton
        />
        <Box
          my={12}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6" color="text.secondary">
            No clinic currently selected.
          </Typography>
          <Typography variant="h6" color="text.secondary">
            The users will be displayed once a clinic is selected through the
            clinics list.
          </Typography>
        </Box>
      </>
    );
  }

  // if there are more than one selected patients

  // clinic is selected, but has no users (the loading of users should have finished)
  if (!isLoading && rows.length === 0)
    return (
      <>
        <PageIntro
          pageTitle={`Users: ${clinicName}`}
          addButtonText={t("admin_create_users")}
          addButtonOnClick={() => setAddModal(true)}
          disableSearchButton
          auth0ButtonOnClick={auth0ButtonOnClick}
          showAuth0Button={false}
        />
        <ClinicInfoSection clinic={clinic} numberOfUsers={rows.length} />
        <Box
          my={12}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6" color="text.secondary">
            {clinicName} has no users.
          </Typography>
        </Box>

        {/* Add user modal */}
        <AddUserModal
          open={addModal}
          onClose={handleAddClose}
          onConfirm={() => insertUser()}
          onCancel={() => cancelAdd()}
          userEmail={userEmail}
          setUserEmail={(e) => setUserEmail(e)}
          clinicName={clinicName}
        />

        {/* Add clinic success/failure toasts */}
        <SnackbarMessage
          open={successToast}
          onClose={() => setSuccessToast(false)}
          success
          text={t("user_add_success")}
        />
        <SnackbarMessage
          open={failureToast}
          onClose={() => setFailureToast(false)}
          text={t("user_add_error")}
        />

        {/* Delete clinic success/failure toasts */}
        <SnackbarMessage
          open={deleteSuccessToast}
          onClose={() => setDeleteSuccessToast(false)}
          success
          text={t("user_delete_success")}
        />
        <SnackbarMessage
          open={deleteFailureToast}
          onClose={() => setDeleteFailureToast(false)}
          text={t("user_delete_error")}
        />
      </>
    );

  return (
    <>
      <PageIntro
        pageTitle={`${t("word_clinic")}: ${clinicName}`}
        addButtonText={t("admin_create_users")}
        addButtonOnClick={() => setAddModal(true)}
        deleteButtonOnClick={() => setDeleteModal(true)}
        onFilterChange={onFilterChange}
        selectedRows={selected}
        disableSearchButton={isLoading}
        clearSearchFilter={() => setSearchTerm("")}
        showAuth0Button={showAuth0Button}
        auth0ButtonOnClick={auth0ButtonOnClick}
      />
      <ClinicInfoSection
        clinic={clinic}
        numberOfUsers={rows.length}
        isLoading={isLoading}
      />

      {ListElement()}

      <PaginationWrapper
        page={page}
        count={pagesCount(filteredRows, 4)}
        onChange={onPaginationChange}
      />

      {/* Add user modal */}
      <AddUserModal
        rows={rows}
        open={addModal}
        onClose={handleAddClose}
        onConfirm={() => insertUser()}
        onCancel={() => cancelAdd()}
        userEmail={userEmail}
        setUserEmail={(e) => setUserEmail(e)}
        clinicName={clinicName}
      />

      <DeleteUserModal
        open={deleteModal}
        onClose={handleDeleteClose}
        onConfirm={() => deleteUsers()}
        onCancel={() => setDeleteModal(false)}
      />

      <Auth0UserModal
        openAuth0UserModal={openAuth0UserModal}
        handleAuth0Close={handleAuth0Close}
        addAuth0User={addAuth0User}
      />

      <SnackbarMessage
        open={Boolean(toast.message)}
        onClose={() => setToast({ success: false, message: null })}
        success={toast.success}
        autoHideDuration={8000}
        text={toast.message}
      />

      {/* Add user success/failure toasts */}
      <SnackbarMessage
        open={successToast}
        onClose={() => setSuccessToast(false)}
        success
        text={t("user_add_success")}
      />
      <SnackbarMessage
        open={failureToast}
        onClose={() => setFailureToast(false)}
        text={t("user_add_error")}
      />

      {/* Delete user success/failure toasts */}
      <SnackbarMessage
        open={deleteSuccessToast}
        onClose={() => setDeleteSuccessToast(false)}
        success
        text={t("user_delete_success")}
      />
      <SnackbarMessage
        open={deleteFailureToast}
        onClose={() => setDeleteFailureToast(false)}
        text={t("user_delete_error")}
      />
    </>
  );
};

export default UsersList;
