// Library methods
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";

// MUI Components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Alert } from "@mui/material";

// Styles
import {
  ModalCustomCancel,
  ModalCustomConfirm,
  ModalPaperProps,
  ModalCustomConfirmIconHandler,
  ModalDialogActions,
} from "../../../styles/muiStylesHelper";

import SearchBox from "../SearchBox";
import { ExamTransferContext } from "../../../contexts/ExamTransferContext";
import { ReportDataContext } from "../../../contexts/ReportDataContext";

const ExamTransferModal = ({
  patientsOptions = [],
  afterTransfer = () => {},
  fromeWhere,
}) => {
  // internationalization
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    openExamTransferModal,
    transferPatient,
    basePatientId,
    handleExamTransferClose,
    handleExamTransfer,
    setTransferPatient,
  } = useContext(ExamTransferContext);

  const { exam } = useContext(ReportDataContext);

  const formattedPatientsOptions = useMemo(
    () =>
      (patientsOptions ?? [])
        .filter((option) => option?.id !== basePatientId)
        .map((option) => {
          return {
            ...option,
            name: `${option?.firstName} ${option?.lastName}`,
          };
        }),
    [patientsOptions, basePatientId]
  );

  const shouldDisable = useMemo(() => {
    let isIncluded = false;
    (patientsOptions ?? []).forEach((option) => {
      if (option?.id === transferPatient?.id) isIncluded = true;
    });
    return !isIncluded;
  }, [patientsOptions, transferPatient?.id]);

  const handleOnClickConfirm = useCallback(async () => {
    try {
      await handleExamTransfer();
      if (fromeWhere === `report`) {
        navigate(`/patients/${transferPatient?.id}/exam/${exam?.id}`);
        window.location.href = `/patients/${transferPatient?.id}/exam/${exam?.id}`;
      } else afterTransfer();
    } catch {}
  }, [
    exam?.id,
    handleExamTransfer,
    navigate,
    afterTransfer,
    transferPatient?.id,
    fromeWhere,
  ]);

  return (
    <Dialog
      open={openExamTransferModal}
      onClose={handleExamTransferClose}
      PaperProps={{
        sx: ModalPaperProps,
      }}
    >
      <DialogContent>
        <Box
          padding={1}
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
        >
          <FontAwesomeIcon icon={faArrowRightArrowLeft} size="lg" />
          <Typography
            style={{ fontWeight: "bold" }}
            variant="h5"
            component="h2"
          >
            {t("exam_transfer_modal_title")}
          </Typography>
        </Box>
        <Box padding={1}>
          {/* Device selector */}
          <SearchBox
            data={formattedPatientsOptions}
            state={transferPatient}
            setState={setTransferPatient}
            label={t("current_patient")}
            propertyBit={4}
          />
        </Box>
        <Box padding={1}>
          <Alert
            variant="outlined"
            severity="warning"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography>{t("exam_transfer_modal_warning")}</Typography>
          </Alert>
        </Box>
      </DialogContent>
      <DialogActions sx={ModalDialogActions}>
        <Button
          variant="text"
          sx={ModalCustomCancel}
          onClick={handleExamTransferClose}
        >
          {t("button_cancel")}
        </Button>
        <Button
          variant="outlined"
          sx={{
            ...ModalCustomConfirm(),
            ...ModalCustomConfirmIconHandler(),
          }}
          disabled={shouldDisable}
          onClick={handleOnClickConfirm}
        >
          {t("button_confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExamTransferModal;
