import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const DisclaimerItem = ({ number }) => {
  const { t } = useTranslation();

  return (
    <li style={{ fontSize: "16px" }}>
      {t(`operating_manual_disclaimer_content${number}`)}
    </li>
  );
};

const DisclaimerList = () => (
  <ol mt={5}>
    {[...Array(4)].map((_, index) => (
      <DisclaimerItem key={index} number={index + 1} />
    ))}
  </ol>
);

const Disclaimers = () => {
  const { t } = useTranslation();

  return (
    <Box id="disclaimers" className="border p-4 rounded-lg">
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        {t("operating_manual_disclaimers")}
      </Typography>

      <DisclaimerList />
    </Box>
  );
};

export default Disclaimers;
