import { Typography, Box } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
import { t } from "i18next";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <Box sx={{ ...sectionContainerStyle, padding: 3 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
          color: "white",
          p: 1,
          pl: 4,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          {t("operating_manual_contact_us")}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pl: 4,
          pr: 4,
          mb: 4,
        }}
      >
        <Typography variant="body1">
          {t("operating_manual_contact_us_one")}{" "}
          <Typography component="span" color="primary.dark">
            support@retinalogik.ca
          </Typography>{" "}
          {t("operating_manual_contact_us_two")}{" "}
          <Typography component="span" color="primary.dark">
            +1 (587) 905-5689
          </Typography>
          .
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
          color: "white",
          p: 1,
          pl: 4,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          {t("operating_manual_contact_us_three")}{" "}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pl: 4,
          pr: 4,
          mb: 4,
        }}
      >
        <Typography variant="body1" gutterBottom>
          RetinaLogik Inc.
        </Typography>
        <Typography variant="body2">3655 36 street NW</Typography>
        <Typography variant="body2">Calgary, Alberta, T2L 1Y8</Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#202338",
          color: "white",
          p: 1,
          pl: 4,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          {t("operating_manual_contact_us_four")}{" "}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pl: 4,
          pr: 4,
          mb: 4,
        }}
      >
        <Typography variant="body1" paragraph>
          {t("operating_manual_contact_us_five")}{" "}
        </Typography>
        <Typography variant="body1">
          {t("operating_manual_contact_us_six")}{" "}
        </Typography>

        <Typography variant="body1">
          {t("operating_manual_contact_us_seven")}{" "}
          <Link to="http://www.retinalogik.ca/" target="_blank">
            www.retinalogik.ca
          </Link>
          .
        </Typography>
      </Box>
    </Box>
  );
};

export default ContactUs;
