const compare = (a, b, orderBy, isAscending) => {
  let bValue, aValue;

  if (orderBy === "algorithm" || orderBy === "gridType") {
    const examSectionsKeys = Object.keys(a).filter((key) =>
      key.includes("Sections")
    );
    const currentExamSectionsKey = examSectionsKeys?.filter(
      (key) => a[key].length > 0
    );
    if (currentExamSectionsKey?.length !== 1) {
      return 0;
    }
    if (!b[currentExamSectionsKey?.[0]]) {
      return 0;
    }
    if (
      orderBy === "gridType" &&
      !a[currentExamSectionsKey?.[0]]?.[0]?.gridType
    )
      return 0;
    bValue =
      orderBy === "algorithm"
        ? b[currentExamSectionsKey?.[0]]?.[0]?.algorithm
        : b[currentExamSectionsKey?.[0]]?.[0]?.gridType;
    aValue =
      orderBy === "algorithm"
        ? a[currentExamSectionsKey?.[0]]?.[0]?.algorithm
        : a[currentExamSectionsKey?.[0]]?.[0]?.gridType;
  } else if (
    orderBy === "completionDate" ||
    orderBy === "nextScheduledExamDate"
  ) {
    aValue = new Date(a[orderBy]);
    bValue = new Date(b[orderBy]);
  } else {
    bValue =
      typeof b[orderBy] === "string" ? b[orderBy].toLowerCase() : b[orderBy];
    aValue =
      typeof a[orderBy] === "string" ? a[orderBy].toLowerCase() : a[orderBy];
  }

  if (aValue < bValue) return isAscending ? -1 : 1;
  if (aValue > bValue) return isAscending ? 1 : -1;
  return 0;
};

// get the comparision function based on if "asc" or "desc"
// Function to get the appropriate comparator based on order
const getComparator = (order, orderUsing) => {
  if (!orderUsing?.length) return () => 0;

  return (a, b) => {
    for (let i = 0; i < orderUsing.length; i++) {
      const orderBy = orderUsing[i];

      let isAscending;
      if (
        (orderBy === "firstName" && i !== 0) ||
        (orderBy === "lastName" && i <= 1)
      ) {
        // Always ascending for firstName and lastName under specific conditions.
        isAscending = true;
      } else if (orderBy === "completedDate" && i !== 0) {
        // Always descending for completedDate when not the first index.
        isAscending = false;
      } else {
        // General sort order for other fields.
        isAscending = order !== "desc";
      }
      const res = compare(a, b, orderBy, isAscending);
      if (res !== 0) return res;
    }
    return 0;
  };
};

export { getComparator };
