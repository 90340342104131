import { Box } from "@mui/material";
import algoliasearch from "algoliasearch";
import { useCallback, useEffect, useRef, useState } from "react";
import { InstantSearch, Hits, Highlight, SearchBox } from "react-instantsearch";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import "./algolia-search.css";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

const AlgoliaSearch = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [hasResults, setHasResults] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const searchContainerRef = useRef(null);

  const index = [
    process.env.REACT_APP_ALGOLIA_INDEX_NAME_ENGLISH,
    process.env.REACT_APP_ALGOLIA_INDEX_NAME_FRENCH,
  ];

  const transformItems = (items, { results }) => {
    setHasResults(results.query !== "" && results.nbHits !== 0);
    return items;
  };

  const handleClick = useCallback(
    (hit) => {
      setIsInputFocused(false);
      const subPath =
        typeof hit?.path === "string"
          ? hit.path.trim().split("/").filter(Boolean).pop()
          : "";
      if (window.location.pathname.includes(hit?.path)) {
        if (hit?.type === "main-link") {
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          const element = document.getElementById(subPath);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
      } else {
        navigate(hit.path);
      }
    },
    [navigate]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setIsInputFocused(false);
      } else {
        setIsInputFocused(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function Hit({ hit }) {
    return (
      <div
        className={styles.hitItem}
        onClick={() => {
          handleClick(hit);
        }}
        style={{ cursor: "pointer" }}
      >
        <article>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography sx={{ fontWeight: 700, fontSize: 16 }}>
              <Highlight attribute="title" hit={hit} />
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: 14 }}>
              <Highlight attribute="description" hit={hit} />
            </Typography>
            <Typography sx={{ fontWeight: 300, fontSize: 12 }}>
              Keywords: [ <Highlight attribute="keywords" hit={hit} /> ]
            </Typography>
          </Box>
        </article>
      </div>
    );
  }

  return (
    <div ref={searchContainerRef} className={styles.searchContainer}>
      <InstantSearch
        searchClient={searchClient}
        indexName={index[t("operating_manual_search_index_index")]}
        insights
      >
        <SearchBox
          classNames={{
            root: `${styles.searchBoxContainer}`,
            input: `${styles.searchInput} px-4 py-3 w-full outline-none transition-all duration-300`,
          }}
          placeholder={t("operating_manual_search_placeholder")}
        />
        <div
          className={`${styles.searchResultsContainer} ${
            hasResults && isInputFocused ? styles.visible : styles.hidden
          }`}
        >
          <Hits hitComponent={Hit} transformItems={transformItems} />
        </div>
      </InstantSearch>
    </div>
  );
};

export default AlgoliaSearch;
