import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";

const SoftwareUpdatesSpecifications = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <Box sx={{ ...sectionContainerStyle, padding: 3 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
          color: "white",
          p: 1,
          pl: 4,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" fontWeight={"bold"}>
          2. {t("operating_manual_software_updates_title")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pl: 4,
          pr: 4,
        }}
      >
        <Typography>
          {t("operating_manual_software_updates_description1")}
        </Typography>
        <br></br>
        <Typography>
          {t("operating_manual_software_updates_description2")}
        </Typography>
        <ol>
          <li>
            <span className="underline font-bold">
              {t("operating_manual_software_updates_description2_1_a")}
            </span>{" "}
            {t("operating_manual_software_updates_description2_1_b")}
          </li>
          <li>
            <span className="underline font-bold">
              {t("operating_manual_software_updates_description2_2_a")}
            </span>{" "}
            {t("operating_manual_software_updates_description2_2_b")}
          </li>
        </ol>
      </Box>
    </Box>
  );
};

export default SoftwareUpdatesSpecifications;
