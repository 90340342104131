import { Box } from "@mui/material";
import Launch from "../../../../../assets/images/operating-manual/appendix/b/launch.png";
import LaunchFrench from "../../../../../assets/images/operating-manual/appendix/b/launch-french.png";
import Calibrate from "../../../../../assets/images/operating-manual/appendix/b/calibrate.png";
import CalibrateFrench from "../../../../../assets/images/operating-manual/appendix/b/calibrate-french.png";
import Countdown from "../../../../../assets/images/operating-manual/appendix/b/countdown.png";
import CountdownFrench from "../../../../../assets/images/operating-manual/appendix/b/countdown-french.png";
import Complete from "../../../../../assets/images/operating-manual/appendix/b/complete.png";
import { useTranslation } from "react-i18next";

const AppendixVisualField = () => {
  const { t } = useTranslation();
  const imgAttributes = [
    [Launch, LaunchFrench],
    [Calibrate, CalibrateFrench],
    [Countdown, CountdownFrench],
  ];

  return (
    <div id="appendix-visual-field">
      <Box
        component="img"
        src={imgAttributes[0][t("operating_manual_image_index")]}
        alt="Appendix b"
        sx={{
          width: "70%",
          height: "auto",
          borderRadius: 1,
          display: "block",
          margin: "0 auto",
        }}
      />

      <Box
        component="img"
        src={imgAttributes[1][t("operating_manual_image_index")]}
        alt="Appendix b"
        sx={{
          width: "70%",
          height: "auto",
          borderRadius: 1,
          mt: 3,
          margin: "0 auto",
          display: "block",
        }}
      />
      <Box
        component="img"
        src={imgAttributes[2][t("operating_manual_image_index")]}
        alt="Appendix b"
        sx={{
          width: "70%",
          height: "auto",
          borderRadius: 1,
          mt: 3,
          margin: "0 auto",
          display: "block",
        }}
      />
      <Box
        component="img"
        src={Complete}
        alt="Appendix b"
        sx={{
          width: "70%",
          margin: "0 auto",
          height: "auto",
          borderRadius: 1,
          mt: 3,
          display: "block",
        }}
      />
    </div>
  );
};

export default AppendixVisualField;
