// Library methods
import { useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider, createTheme, useMediaQuery } from "@mui/material";

// MUI Components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// Components
import AddCommentModal from "../../../../../components/UI/AddCommentModal";

// Assets
import eyeLogo from "../../../../../assets/images/logos/eyelogo.png";

// Utilities
import useClinic from "../../../../../hooks/useClinic";
import { calculateAgeFromExamDate } from "../../../../../utils/examReportsHelper";
import { getLocalDateAndTimeWithZone } from "../../../../../utils/localeHelper";
import { ValidatePDFContext } from "../../../../../contexts/ValidatePDFContext";
import { ReportDataContext } from "../../../../../contexts/ReportDataContext";
import {
  ExamTypeContext,
  EyeContext,
} from "../../../../../contexts/ExamPropertyContext";
import {
  ReportInfoHeaderContent,
  ReportInfoShared,
} from "../../../../../components/UI/ReportInfoShared";
import { ReportEachSectionContext } from "../../../../../contexts/CommentContext";

const ReportInfo = () => {
  // internationalization
  const { t } = useTranslation();
  const { clinicSettings } = useClinic();
  const { sectionId: firstSectionId, sectionId2 } =
    useContext(ReportDataContext);
  const { typeColorVision, titles } = useContext(ExamTypeContext);
  const { forBackendPdf } = useContext(ValidatePDFContext);
  const { eyeRight, eyeBoth } = useContext(EyeContext);

  const { patient, exam, clinicTimezone } = useContext(ReportDataContext);
  const { examReport, eyeReport, duration } = useContext(
    ReportEachSectionContext
  );

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: ["Arial", "Verdana", "sans-serif"],
      },
    },
  });

  const isWiderThanSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const localDateAndTimeWithZone = useMemo(
    () =>
      examReport?.timing?.start
        ? getLocalDateAndTimeWithZone(
            forBackendPdf ? clinicTimezone : null,
            examReport.timing.start
          )
        : null,
    [clinicTimezone, examReport?.timing?.start, forBackendPdf]
  );

  const gridSizeData = useMemo(
    () => [
      { sm: 4, md: 4, lg: 4, xl: 4 },
      {
        sm: 3,
        md: {
          sm: 3,
          md:
            forBackendPdf &&
            clinicSettings?.monocularReportPdfLayoutOption === "Single" &&
            firstSectionId != null &&
            sectionId2 != null
              ? 3
              : 2.4,
          lg: 3,
          xl: 2.4,
        },
      },
    ],
    [
      clinicSettings?.monocularReportPdfLayoutOption,
      firstSectionId,
      forBackendPdf,
      sectionId2,
    ]
  );

  const eyeAbbreviation = useMemo(
    () => (eyeReport === eyeBoth ? "OU" : eyeReport === eyeRight ? "OD" : "OS"),
    [eyeReport, eyeBoth, eyeRight]
  );

  const infoData = useMemo(
    () => [
      [
        {
          title: t("word_eye"),
          body:
            eyeReport === eyeRight
              ? t("word_right")
              : eyeReport === eyeBoth
                ? t("word_binocular")
                : t("word_left"),
        },
        { title: t("word_sex"), body: exam?.patient?.sex },
        {
          title: t("report_age"),
          body: exam?.age
            ? exam.age
            : calculateAgeFromExamDate(patient.dateOfBirth, exam?.creationDate),
        },
      ],
      [
        {
          title: t("report_exam_date"),
          body: localDateAndTimeWithZone?.date ?? t("word_na"),
          isBlack: true,
        },
        {
          title: t("report_exam_time"),
          body: localDateAndTimeWithZone?.time ?? t("word_na"),
          isBlack: true,
        },
        { title: t("report_exam_duration"), body: duration },
      ],
    ],
    [
      duration,
      exam?.age,
      exam?.creationDate,
      exam?.patient?.sex,
      eyeReport,
      localDateAndTimeWithZone?.date,
      localDateAndTimeWithZone?.time,
      patient?.dateOfBirth,
      eyeRight,
      eyeBoth,
      t,
    ]
  );
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        columnSpacing={2}
        my={1}
        sx={{ display: "flex", alignItems: "start" }}
      >
        <Grid container item xs={12}>
          {forBackendPdf && (
            <Grid
              item
              xs={1}
              style={{
                paddingLeft: 15,
                alignItems: "center",
              }}
            >
              <img
                src={eyeLogo}
                alt="eye logo"
                style={{ width: "80%", height: "auto" }}
              />
            </Grid>
          )}
          <Grid container item xs={forBackendPdf ? 11 : 12} mb={1.5}>
            <Grid
              item
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              border={1}
              xs={12}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  background: "black",
                  width: 70,
                  height: "100%",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                mr={isWiderThanSmallScreen ? 2 : 1}
                xs={1.5}
                sm={1}
              >
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {eyeAbbreviation}
                </Typography>
              </Grid>
              <ReportInfoHeaderContent
                isWiderThanSmallScreen={isWiderThanSmallScreen}
                examTypeTranslation={`${t(titles[typeColorVision])} - `}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* color vision */}
        <Grid container item display="flex" justifyContent={"space-between"}>
          <ReportInfoShared data={infoData} gridSizeData={gridSizeData} />
        </Grid>
      </Grid>
      <AddCommentModal isColorBlind={true} />
    </ThemeProvider>
  );
};

export default ReportInfo;
