import { Typography } from "@mui/material";
import AccountRegistration from "./AccountRegistration";
import TurningOnVRDevice from "./TurningOnVRDevice";
import CreatingExam from "./CreatingExam";
import LaunchingExam from "./LaunchingExam";
import ViewingResults from "./ViewingResults";
import { useContext } from "react";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";
import { useTranslation } from "react-i18next";
import ClinicSetting from "./ClinicSettings";
import CreatePatient from "./CreatePatient";

const InstructionsForUse = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <>
      <div style={sectionContainerStyle}>
        <Typography variant="h5" gutterBottom fontWeight={"bold"}>
          4. {t("operating_manual_instructions_for_use_title")}
        </Typography>
      </div>
      <AccountRegistration />
      <ClinicSetting />
      <TurningOnVRDevice />
      <CreatePatient />
      <CreatingExam />
      <LaunchingExam />
      <ViewingResults />
    </>
  );
};

export default InstructionsForUse;
