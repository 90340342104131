import { Box, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";

const Features = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  const featureCount = 13;
  return (
    <Box id="features" sx={{ ...sectionContainerStyle, padding: 3 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
          color: "white",
          p: 1,
          pl: 4,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" fontWeight={"bold"}>
          3.1 {t("operating_manual_features_title")}
        </Typography>
      </Box>

      <div
        className="my-5 bg-white p-6 rounded-lg shadow-lg max-w-4xl mx-auto"
        style={{ color: "#BD1A7D" }}
      >
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          style={{ marginBottom: "1rem" }}
          spacing={2}
        >
          <Grid item xs={3}>
            <span className="font-bold text-black rounded-md text-left">
              {t(`operating_manual_features_column1`)}
            </span>
          </Grid>
          <Grid item xs={9}>
            <span className="font-bold text-black rounded-md text-left">
              {t(`operating_manual_features_column2`)}
            </span>
          </Grid>
        </Grid>
        {[...Array(featureCount)].map((_, index) => (
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            style={{ marginBottom: "1rem" }}
            spacing={2}
          >
            <Grid item xs={3}>
              <span className="font-bold text-pink rounded-md text-left">
                {t(`operating_manual_features_header${index + 1}`)}
              </span>
            </Grid>
            <Grid item xs={9}>
              <span className="text-black rounded-md text-left">
                {t(`operating_manual_features_content${index + 1}`)}
              </span>
            </Grid>
          </Grid>
        ))}
      </div>
    </Box>
  );
};

export default Features;
