import { Typography, Box, Grid } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Description2 from "../../../../../assets/images/operating-manual/4/description2.png";
import Description2French from "../../../../../assets/images/operating-manual/4/description2-french.png";
import Description3 from "../../../../../assets/images/operating-manual/4/description3.png";
import Description3French from "../../../../../assets/images/operating-manual/4/description3-french.png";

const ClinicSettings = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  const imgAttributes = [
    [Description2, Description2French],
    [Description3, Description3French],
  ];

  return (
    <Box
      id="configure-clinic-settings"
      sx={{ ...sectionContainerStyle, padding: 3 }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
          color: "white",
          p: 1,
          pl: 4,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          4.2. {t("operating_manual_configure_clinic_setting_title")}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pl: 4,
          pr: 4,
          gap: 2,
        }}
      >
        <Grid container xs={12}>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              1. {t("operating_manual_configure_clinic_setting_description1")}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              2. {t("operating_manual_configure_clinic_setting_description2")}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={imgAttributes[0][t("operating_manual_image_index")]}
              alt="Description 2"
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              3. {t("operating_manual_configure_clinic_setting_description3")}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={imgAttributes[1][t("operating_manual_image_index")]}
              alt="Description 3"
              sx={{ width: "60%" }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ClinicSettings;
