import { Box, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
const TestPatterns = () => {
  const { t } = useTranslation();
  const featureCount = 6;
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <Box id="test-patterns" sx={{ ...sectionContainerStyle, padding: 3 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
          color: "white",
          p: 1,
          pl: 4,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" fontWeight={"bold"}>
          3.2 {t("operating_manual_test_patterns_title")}
        </Typography>
      </Box>
      <div
        className="my-5 bg-white p-6 rounded-lg shadow-lg max-w-4xl mx-auto"
        style={{ color: "#BD1A7D" }}
        id="test-patterns"
      >
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          style={{ marginBottom: "1rem" }}
          spacing={2}
        >
          <Grid item xs={2}>
            <span className="font-bold text-black rounded-md text-left">
              {t(`operating_manual_test_patterns_column1`)}
            </span>
          </Grid>
          <Grid item xs={6}>
            <span className="font-bold text-black rounded-md text-left">
              {t(`operating_manual_test_patterns_column2`)}
            </span>
          </Grid>
          <Grid item xs={2}>
            <span className="font-bold text-black rounded-md text-left">
              {t(`operating_manual_test_patterns_column3`)}
            </span>
          </Grid>
          <Grid item xs={2}>
            <span className="font-bold text-black rounded-md text-left">
              {t(`operating_manual_test_patterns_column4`)}
            </span>
          </Grid>
        </Grid>
        {[...Array(featureCount)].map((_, index) => (
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            style={{ marginBottom: "1rem" }}
            spacing={2}
          >
            <Grid item xs={2}>
              <span className="font-bold text-pink rounded-md text-left">
                {t(`operating_manual_test_patterns_header${index + 1}`)}
              </span>
            </Grid>
            <Grid item xs={6}>
              <span className="text-black rounded-md text-left">
                {t(`operating_manual_test_patterns_content${index + 1}`)}
              </span>
            </Grid>
            <Grid item xs={2}>
              <span className="text-black rounded-md text-left">
                {t(`operating_manual_test_patterns_content${index + 1}_a`)}
              </span>
            </Grid>
            <Grid item xs={2}>
              <span className="text-black rounded-md text-left">
                {t(`operating_manual_test_patterns_content${index + 1}_b`)}
              </span>
            </Grid>
          </Grid>
        ))}
      </div>
    </Box>
  );
};

export default TestPatterns;
