// Utilities
import { useContext } from "react";
import { getImageInfo } from "../../../../../../utils/examReportsHelper";

// Styles
import styles from "./index.module.css";
import { ValidatePDFContext } from "../../../../../../contexts/ValidatePDFContext";

const QuadrantBuilder = ({
  gridColumns = 14,
  gridRows = 14,
  spotHeight = 10.5, // in px
  spotWidth = 10.5, // in px
  gridGap = 0, // in px
  gridValues = [], // should be an array, cointaining an array of items per row (based on the informed gridColumns and gridRows)
  mirrorHorizontally = false,
  mirrorVertically = false,
}) => {
  const { forBackendPdf } = useContext(ValidatePDFContext);
  const shouldMirrorHorizontally = mirrorHorizontally && !mirrorVertically;
  const shouldMirrorVerically = !mirrorHorizontally && mirrorVertically;
  const shouldMirrorDiagonally = mirrorHorizontally && mirrorVertically;
  const scale = forBackendPdf ? 1.25 : 1;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${gridColumns}, ${spotWidth * scale}px)`,
        gridTemplateRows: `repeat(${gridRows}, ${spotHeight * scale}px)`,
        backgroundColor: "white",
        height: `${gridRows * spotHeight * scale}px`,
        width: `${gridColumns * spotWidth * scale}px`,
        gridGap,
        /* mirror horizontally */
        ...(shouldMirrorHorizontally && { transform: "scaleX(-1)" }),
        /* mirror vertically */
        ...(shouldMirrorVerically && { transform: "scaleY(-1)" }),
        /* mirror both horizontally and vertically */
        ...(shouldMirrorDiagonally && { transform: "scaleX(-1) scaleY(-1)" }),
      }}
    >
      {gridValues.map((row, indexGrid) => {
        return row.map((item, indexRow) => {
          const { imageSrc, alt } = getImageInfo(item);
          return (
            <img
              data-testid={`dynamic-gray-scale-image`}
              key={`${indexGrid}${indexRow}`}
              src={imageSrc}
              alt={alt}
              className={styles.imageRender}
              width={spotWidth * scale}
              height={spotHeight * scale}
            />
          );
        });
      })}
    </div>
  );
};

export default QuadrantBuilder;
