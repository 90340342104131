import { Box, Typography, Grid } from "@mui/material";
import { useContext } from "react";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";
import Caution from "../../../../assets/images/operating-manual/appendix/a/caution.png";
import Manufacturer from "../../../../assets/images/operating-manual/appendix/a/manufacturer.png";
import { useTranslation } from "react-i18next";

const AppendixASymbolGlossary = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  const imgAttributes = [
    [
      { src: Caution, alt: "caution" },
      { src: Manufacturer, alt: "manufacturer" },
    ],
  ];

  return (
    <>
      <Box sx={{ ...sectionContainerStyle, padding: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background:
              "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
            color: "white",
            p: 1,
            pl: 4,
            borderRadius: 2,
            mb: 2,
            boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            {t("operating_manual_appendix_a")}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pl: 4,
            pr: 4,
            mb: 4,
          }}
        >
          <Grid container xs={12} spacing={2} alignItems="center">
            {[...Array(2)].map((_, index) => (
              <Grid container item xs={6} alignItems="center">
                <Grid item xs={1}>
                  <img
                    src={imgAttributes[0][index].src}
                    alt={imgAttributes[0][index].alt}
                    style={{ width: "60%", objectFit: "cover" }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Typography>
                    {t(`operating_manual_appendix_a_${index + 1}`)}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AppendixASymbolGlossary;
