// Library methods
import { useState, useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { throttle } from "lodash";

// MUI Components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Components
import TodayExamCard from "./TodayExamCard";
import LinkDeviceModal from "../../../components/UI/LinkDeviceModal";
import UnavailableDeviceModal from "../../../components/UI/UnavailableDeviceModal";
import LiveUpdateModal from "../../../components/UI/LiveUpdateModal";
import ListHeader from "../../../components/UI/ListHeader";
import PaginationWrapper from "../../../components/UI/PaginationWrapper";
import PageIntro from "../../../components/UI/PageIntro";
import SnackbarMessage from "../../../components/UI/SnackbarMessage";
import Loader from "../../../components/UI/Loader";

// Utilities
import { linkExamToDevice } from "../../../services/Device";
import { getComparator } from "../../../utils/tableHelper";
import { itemsRendering, pagesCount } from "../../../utils/paginationHelper";
import { getVisualField } from "../../../utils/examReportsHelper";
import useClinic from "../../../hooks/useClinic";
import { useDevices } from "../../../hooks/useDevices";
import { useExamStatus } from "../../../hooks/useExamStatus";

// Contexts
import { useWindowDimensions } from "../../../contexts/WindowSizeContext";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { ValidatePDFContext } from "../../../contexts/ValidatePDFContext";

const TodayExamsList = ({
  connection,
  isLoading,
  patients,
  rows,
  devicesOptions,
  refetchDevices,
  refetchExams,
  devicesWithExams,
  handleViewReport,
  isLoadingSignalR,
  maxNumOfRows,
  setMaxNumOfRows,
}) => {
  // clinic context
  const { clinicId } = useClinic();

  // internationalization
  const { t } = useTranslation();

  // auth0
  const { getAccessTokenSilently } = useAuth0();

  const { unlinkDeviceModal, handleUnlinkDeviceClick } = useDevices(
    connection,
    clinicId,
    patients,
    devicesWithExams
  );

  const { getExamStatus } = useExamStatus();

  const orderByPatientName = ["firstName", "lastName"];
  const orderByTypeAlg = ["type", "algorithm", "gridType"];
  // states init
  const [order, setOrder] = useState("asc");
  const [orderByOptions, setOrderByOptions] = useState(orderByPatientName);
  const [selectedExam, setSelectedExam] = useState("");
  // const [selectedLiveUpdateExam, setSelectedLiveUpdateExam] =
  //   useState(undefined);
  const [editExamDevice, setEditExamDevice] = useState("");
  const [page, setPage] = useState(1);

  // state for add and delete exams modals
  const [linkDeviceModal, setLinkDeviceModal] = useState(false);
  // const [liveUpdateModal, setLiveUpdateModal] = useState(false);

  // loading states
  const [linkLoading, setLinkLoading] = useState(false);

  // state to check if unavailableDeviceModal should be displayed or not
  const [unavailableDeviceModal, setUnavailableDeviceModal] = useState(false);

  // device link toast states
  const [deviceLinkSuccessToast, setDeviceLinkSuccessToast] = useState(false);
  const [deviceLinkFailureToast, setDeviceLinkFailureToast] = useState(false);

  const examCardRef = useRef(null);
  const [examCardHeight, setExamCardHeight] = useState(0);
  const { height } = useWindowDimensions();

  const theme = useTheme();
  const smallerThanXlScreen = useMediaQuery(theme.breakpoints.down("xl"));

  // head rows
  const headCells = [
    // This one is displayed before the others, on a different grid.
    {
      id: "adjustment",
      label: null,
      gridSize: 0.3,
      orderUsing: [],
    },
    {
      id: "device",
      label: t("exams_column_device"),
      gridSize: 1.8,
      orderUsing: [],
    },
    // The below ones are displayed within the same grid, and should sum to 11 (that will be displayed together with a checkbox of grid size 1).
    {
      id: "patient",
      label: t("current_patient"),
      gridSize: 2,
      orderUsing: [...orderByPatientName, ...orderByTypeAlg],
    },
    {
      id: "status",
      label: t("exams_column_status"),
      gridSize: 2,
      orderUsing: ["statusOrder", ...orderByPatientName, ...orderByTypeAlg],
    },
    {
      id: "eye",
      label: t("exams_column_eye"),
      gridSize: 2.4,
      orderUsing: ["eyesOrder", ...orderByPatientName, ...orderByTypeAlg],
    },
    {
      id: "type",
      label: t("exams_column_type"),
      gridSize: 2.8,
      orderUsing: [...orderByTypeAlg, ...orderByPatientName],
    },
    {
      id: "manage",
      label: "",
      gridSize: 1,
    },
  ];

  // handles the device linking when there is only one device option
  const handleLinkDeviceWithoutModal = useCallback(
    async (examId, deviceId) => {
      try {
        // get token
        const token = await getAccessTokenSilently();
        const examData = { examId };
        await linkExamToDevice(token, clinicId, deviceId, examData);

        setSelectedExam("");
        setDeviceLinkSuccessToast(true);
      } catch (error) {
        setSelectedExam("");
        setDeviceLinkFailureToast(true);
      }
    },
    [getAccessTokenSilently, clinicId]
  );

  // handles the link device modal confirmation
  const handleLinkDevice = useCallback(
    async (id) => {
      // close the unlink Modal
      setLinkDeviceModal(false);
      setLinkLoading(true);
      // link
      try {
        // get token
        const token = await getAccessTokenSilently();
        const examData = { examId: selectedExam.id ?? id };
        await linkExamToDevice(token, clinicId, editExamDevice?.val, examData);

        setSelectedExam("");
        setDeviceLinkSuccessToast(true);
        setTimeout(() => {
          setLinkLoading(false);
        }, 2000);
      } catch (error) {
        setSelectedExam("");
        setDeviceLinkFailureToast(true);
        setLinkLoading(false);
      }
    },
    [getAccessTokenSilently, selectedExam.id, clinicId, editExamDevice]
  );

  // handle on close of link device modal
  const handleLinkDeviceClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setLinkDeviceModal(false);
    }
  };

  // pagination change
  const onPaginationChange = (event, value) => {
    setPage(value);
  };

  // handle sort Request (it can now order considering two fields)
  const handleRequestSort = (event, property) => {
    const isAsc =
      orderByOptions.length &&
      orderByOptions[0] === property[0] &&
      order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderByOptions(property);
  };

  // refetch exams when devicesWithExams length has changed (when an exam gets completed, this happens)
  useEffect(() => {
    const controller = new AbortController();
    refetchExams(controller.signal);
    return () => {
      controller.abort();
    };
  }, [devicesWithExams?.length, refetchExams]);

  // Filtered rows
  const filteredRows = useMemo(
    () =>
      rows ??
      []
        .filter(
          (row) =>
            row?.visualFieldSections?.length ||
            row?.contrastSensitivitySections?.length ||
            row?.colorVisionSections?.length ||
            row?.visualAcuitySections?.length
        ) // disconsider the exams with no sections
        .map((row) => ({
          ...row,
          // adding some helpful information to the exam
          statusOrder: getExamStatus(row, devicesWithExams)?.status,
          tutorialOrder: row.requiresTutorial ? t("word_yes") : t("word_no"),
          visualFieldOrder: getVisualField(row).toLowerCase(),
          eyesOrder: (
            row?.visualFieldSections ||
            row?.contrastSensitivitySections ||
            row?.colorVisionSections ||
            row?.visualAcuitySections
          )
            .sort((a, b) => {
              if (typeof a.eye === "string" && typeof b.eye === "string") {
                return a.eye.localeCompare(b.eye);
              }
              // or any other logic to handle non-string cases
              return 0;
            })
            .map((section) => section.eye)
            .join(""),
          deviceName: row.associatedDevice?.device?.alias || "-",
        })),
    [devicesWithExams, getExamStatus, rows, t]
  );

  // sorted rows
  const sortedRows = useMemo(() => {
    if (!filteredRows?.length) return [];
    const notStartedDeviceExams = [];
    const notLiveExams = [];
    const liveExams = filteredRows.filter((row) => {
      const device = devicesWithExams?.find(
        (item) => item?.associatedExam?.exam?.id === row?.id
      );

      if (
        device &&
        device?.deviceStatus?.stage?.stageType &&
        device?.deviceStatus?.stage?.stageType !== "Not started"
      )
        return true;
      if (device) {
        notStartedDeviceExams.push(row);
        return false;
      } else {
        notLiveExams.push(row);
        return false;
      }
    });
    const sortedLiveExams = (liveExams || []).sort(
      // getComparator(order, orderBy, orderByAlternative)
      getComparator(order, orderByOptions)
    );
    const sortedNotStartedDeviceExams = (notStartedDeviceExams || []).sort(
      // getComparator(order, orderBy, orderByAlternative)
      getComparator(order, orderByOptions)
    );
    const sortedNotLiveExams = (notLiveExams || []).sort(
      // getComparator(order, orderBy, orderByAlternative)
      getComparator(order, orderByOptions)
    );

    return [
      ...sortedLiveExams,
      ...sortedNotStartedDeviceExams,
      ...sortedNotLiveExams,
    ];
  }, [devicesWithExams, filteredRows, order, orderByOptions]);

  const availableDevicesOptions = useMemo(() => {
    if (!devicesOptions?.length) return [];
    else
      return devicesOptions.filter(
        (option) => option.webserviceCompatible === true
      );
  }, [devicesOptions]);

  const handleUnavailableDeviceClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setUnavailableDeviceModal(false);
    }
  };

  // handle link device click
  const handleLinkDeviceClick = useCallback(
    (exam) => {
      const autopopulate = async () => {
        setEditExamDevice("");
      };
      autopopulate();
      setSelectedExam(exam);

      // in case there are no option, show unavailable modal
      if (availableDevicesOptions.length <= 0) {
        setUnavailableDeviceModal(true);
      } else if (availableDevicesOptions.length > 1) {
        // in case there are many options, show modal with available options
        setLinkDeviceModal(true);
      } else {
        // in case there's only one device, we link it directly
        handleLinkDeviceWithoutModal(exam.id, availableDevicesOptions[0].id);
      }
    },
    [availableDevicesOptions, handleLinkDeviceWithoutModal]
  );

  const rowsToRender = useMemo(() => {
    return itemsRendering(
      sortedRows,
      page,
      smallerThanXlScreen ? 6 : Math.max(maxNumOfRows, 4)
    );
  }, [page, sortedRows, maxNumOfRows, smallerThanXlScreen]);

  const totalPages = useMemo(
    () =>
      pagesCount(
        sortedRows,
        smallerThanXlScreen ? 6 : Math.max(maxNumOfRows, 4)
      ),
    [maxNumOfRows, smallerThanXlScreen, sortedRows]
  );

  //  calculate the number of rendered rows
  useEffect(() => {
    if (examCardRef.current) {
      const throttledFunc = throttle(() => {
        setExamCardHeight(examCardRef.current.offsetHeight);
      }, 1000);
      throttledFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examCardRef.current, examCardHeight]);

  // calculate the number of rendered rows
  useEffect(() => {
    if (height && examCardHeight) {
      setMaxNumOfRows(
        Math.floor((height - examCardHeight * 9) / examCardHeight)
      );
      const pageCount = pagesCount(
        sortedRows,
        smallerThanXlScreen ? 6 : Math.max(maxNumOfRows, 4)
      );
      if (pageCount < page) setPage(pageCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxNumOfRows, page, examCardHeight, height]);

  const ListElement = () =>
    isLoading ? (
      <Loader containerHeight="30vh" />
    ) : (
      <Grid container mt={{ xs: 0, sm: 3 }}>
        <Grid item xs={12}>
          <ListHeader
            headCells={headCells}
            order={order}
            orderBy={orderByOptions?.[0] ?? "firstName"}
            onRequestSort={handleRequestSort}
            rowCount={rowsToRender.length}
            showCheckbox={false}
            checkBoxGrid={0}
            shouldDisplayGrid="lg"
            displayFirtsBeforeCheckbox
          />
          {rowsToRender.length > 0 ? (
            <Box mt={1}>
              {rowsToRender.map((row) => {
                return (
                  <TodayExamCard
                    key={row.id}
                    row={row}
                    patientId={row?.patient?.id}
                    headCells={headCells}
                    handleViewReportClick={handleViewReport}
                    handleLinkDeviceClick={handleLinkDeviceClick}
                    handleUnlinkDeviceClick={handleUnlinkDeviceClick}
                    associatedExamDevices={devicesWithExams}
                    linkLoading={linkLoading}
                    unlinkLoading={linkLoading}
                    ref={examCardRef}
                  />
                );
              })}
            </Box>
          ) : (
            <Box my={8} display="flex" justifyContent="center">
              <Typography
                noWrap
                variant="body1"
                sx={{ textTransform: "capitalize" }}
                color="text.secondary"
              >
                {t("word_no_today_exams")}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    );

  return (
    <>
      <PageIntro
        pageTitle={t("Today")}
        showAddButton={false}
        showDeleteButton={false}
        showExpandButton={false}
        showFilterInput={false}
        desktopMarginTop={0}
        desktopMarginBottom={0}
      />
      {ListElement()}
      {rows?.length > rowsToRender?.length && (
        <PaginationWrapper
          page={page}
          count={totalPages}
          onChange={onPaginationChange}
        />
      )}

      {/* Unlink device exam modal */}
      {unlinkDeviceModal}

      {/* Link device modal */}
      <LinkDeviceModal
        open={linkDeviceModal}
        onClose={handleLinkDeviceClose}
        onConfirm={() => handleLinkDevice()}
        onCancel={() => {
          setSelectedExam("");
          setLinkDeviceModal(false);
        }}
        devicesOptions={availableDevicesOptions}
        editExamDevice={editExamDevice}
        setEditExamDevice={setEditExamDevice}
        loading={linkLoading}
      />

      {/* Unavailable device modal */}
      <UnavailableDeviceModal
        open={unavailableDeviceModal}
        onClose={handleUnavailableDeviceClose}
        onCancel={() => setUnavailableDeviceModal(false)}
      />

      {/* Live update modal */}
      <ValidatePDFContext.Provider value={{ forBackendPdf: false }}>
        <LiveUpdateModal
          connection={connection}
          refetchDevices={refetchDevices}
          refetchExams={refetchExams}
          rows={rows}
          isLoadingSignalR={isLoadingSignalR}
          devicesWithExams={devicesWithExams}
        />
      </ValidatePDFContext.Provider>

      {/* Link device success/failure toasts */}
      <SnackbarMessage
        open={deviceLinkSuccessToast}
        onClose={() => setDeviceLinkSuccessToast(false)}
        success
        text={t("device_link_success")}
      />
      <SnackbarMessage
        open={deviceLinkFailureToast}
        onClose={() => setDeviceLinkFailureToast(false)}
        text={t("device_link_error")}
      />
    </>
  );
};

export default TodayExamsList;
