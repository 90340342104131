import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useTranslation } from "react-i18next";

const Usage = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  return (
    <Box id="usage" sx={{ ...sectionContainerStyle, padding: 3 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
          color: "white",
          p: 1,
          pl: 4,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" fontWeight={"bold"}>
          1.2 {t("operating_manual_usage_title")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pl: 4,
          pr: 4,
        }}
      >
        <Typography>{t("operating_manual_usage_description")}</Typography>
        <ul>
          <li>{t("operating_manual_usage_optician")}</li>
          <li>{t("operating_manual_usage_nurse")}</li>
          <li>{t("operating_manual_usage_technician")}</li>
          <li>{t("operating_manual_usage_opthalmologist")}</li>
          <li>{t("operating_manual_usage_optometrists")}</li>
          <li>{t("operating_manual_usage_clinic_assignment")}</li>
        </ul>
      </Box>
    </Box>
  );
};

export default Usage;
