import React from "react";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const CircularPlusIcon = () => {
  return (
    <IconButton
      sx={{
        backgroundColor: "grey.500",
        width: 30,
        height: 30,
        borderRadius: "50%",
        "&:hover": {
          backgroundColor: "grey.400",
        },
      }}
    >
      <AddIcon sx={{ color: "white" }} />
    </IconButton>
  );
};

export default CircularPlusIcon;
