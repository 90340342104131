import { Box, Typography } from "@mui/material";
import WarningsPrecautions from "./WarningsPrecautions";
import { useContext } from "react";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";
import { useTranslation } from "react-i18next";

const ImportantInfo = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  return (
    <>
      <Box sx={{ ...sectionContainerStyle, padding: 3 }}>
        <Typography variant="h5" gutterBottom fontWeight={"bold"}>
          {t("operating_manual_important_info_title")}
        </Typography>
        <Typography>
          {t("operating_manual_important_info_description1")}
        </Typography>
        <Typography>
          {t("operating_manual_important_info_description2")}
        </Typography>
        <br />
        <WarningsPrecautions />
      </Box>
    </>
  );
};

export default ImportantInfo;
