import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { linkExamToDevice, unlinkExamFromDevice } from "../services/Device";
import { useAuth0 } from "@auth0/auth0-react";
import SnackbarMessage from "../components/UI/SnackbarMessage";
import { useTranslation } from "react-i18next";
import LinkDeviceModalGeneral from "../components/UI/LinkDeviceModalGeneral";
import UnlinkDeviceModal from "../components/UI/UnlinkDeviceModal";
import { getExams } from "../services/Exam";
import { sortByDate } from "../utils/dateHelper";
import { useExamStatus } from "./useExamStatus";
import { LiveUpdateStatusContext } from "../contexts/LiveUpdateStatusContext";

export const useDevices = (
  connection,
  clinicId,
  patients,
  devicesWithExams
) => {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { getExamStatus } = useExamStatus();
  const { exam, setExam } = useContext(LiveUpdateStatusContext);
  const [unlinkDeviceModalOpen, setUnlinkDeviceModalOpen] = useState(false);
  const [linkDeviceModalOpen, setLinkDeviceModalOpen] = useState(false);
  const [unlinkLoading, setUnlinkLoading] = useState(false);
  const [linkLoading, setLinkLoading] = useState(false);
  const [unlinkSuccessToast, setUnlinkSuccessToast] = useState(false);
  const [unlinkFailureToast, setUnlinkFailureToast] = useState(false);
  const [linkSuccessToast, setLinkSuccessToast] = useState(false);
  const [linkFailureToast, setLinkFailureToast] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState({});

  const [deviceLinkPatient, setDeviceLinkPatient] = useState({
    name: "",
    val: "",
  });
  const [deviceLinkExam, setDeviceLinkExam] = useState({ name: "", val: "" });
  const [availableExams, setAvailableExams] = useState([]);

  useEffect(() => {
    if (!linkDeviceModalOpen) {
      setDeviceLinkPatient({ name: "", val: "" });
      setDeviceLinkExam("");
    }
  }, [linkDeviceModalOpen]);

  useEffect(() => {
    setDeviceLinkExam({ name: "", val: "" });
    setAvailableExams([]);
  }, [deviceLinkPatient]);

  // handles the unlink device button click
  const handleUnlinkDeviceClick = useCallback((device) => {
    setSelectedDevice(device);
    setUnlinkDeviceModalOpen(true);
  }, []);

  // handles the link device button click
  const handleLinkDeviceClick = useCallback((device) => {
    setSelectedDevice(device);
    setLinkDeviceModalOpen(true);
  }, []);

  const handleUnlinkDeviceClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setUnlinkDeviceModalOpen(false);
    }
  };

  // handle on close of link device modal
  const handleLinkDeviceClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setLinkDeviceModalOpen(false);
    }
  };

  // handles the unlink device modal confirmation
  const handleUnlinkDevice = useCallback(async () => {
    // close the unlink Modal
    setUnlinkDeviceModalOpen(false);
    setUnlinkLoading(true);
    // unlink
    try {
      // get token
      const token = await getAccessTokenSilently();

      // unlink on the backend
      await unlinkExamFromDevice(token, clinicId, selectedDevice?.id);

      // update the device stage

      setUnlinkSuccessToast(true);
      if (exam?.id === selectedDevice?.associatedExam?.exam?.id) setExam(null);
      setTimeout(() => {
        setUnlinkLoading(false);
      }, 1500);
    } catch (error) {
      setUnlinkFailureToast(true);
      setUnlinkLoading(false);
    }
  }, [clinicId, exam, getAccessTokenSilently, selectedDevice, setExam]);

  // handles the link device modal confirmation
  const handleLinkDevice = useCallback(
    async (examId) => {
      // close the link Modal
      setLinkDeviceModalOpen(false);
      setLinkLoading(true);
      // link
      try {
        // get token
        const token = await getAccessTokenSilently();
        const examData = { examId };
        await linkExamToDevice(token, clinicId, selectedDevice?.id, examData);
        // await

        setLinkSuccessToast(true);
        setTimeout(() => {
          setLinkLoading(false);
        }, 2000);
      } catch (error) {
        setLinkFailureToast(true);
        setLinkLoading(false);
      }
    },
    [clinicId, getAccessTokenSilently, selectedDevice]
  );

  // method that fetches exams data
  const fetchExams = useCallback(async () => {
    if (!deviceLinkPatient?.val || deviceLinkPatient?.val === "") return null;
    try {
      // get authToken
      const token = await getAccessTokenSilently();
      // get the patient and the patient's exams
      const examsData = await getExams(token, clinicId, deviceLinkPatient?.val);

      return examsData;
    } catch (e) {
      return null;
    }
  }, [clinicId, deviceLinkPatient, getAccessTokenSilently]);

  const fetchAndFilterExams = useCallback(async () => {
    const exams = await fetchExams();
    if (!exams?.length) {
      setAvailableExams([]);
      return;
    }
    const filteredExams = exams.filter((exam) => {
      const status = getExamStatus(exam, devicesWithExams);
      // consider exams that haven't started or only left eye is completed, having no linked device
      return (
        !status?.device &&
        (status?.status === t("word_not_started") ||
          status?.status === t("word_right_eye_completed"))
      );
    });
    setAvailableExams(sortByDate(filteredExams, "creationDate"));
  }, [devicesWithExams, fetchExams, getExamStatus, t]);

  const checkIfDeviceHasExamInProgress = useCallback(
    (deviceId) => {
      if (!deviceId) return false;

      // get the device
      const device = devicesWithExams?.find((item) => item?.id === deviceId);
      const hasExam = device?.associatedExam;

      if (!device || !hasExam) return false;
      if (device?.deviceStatus?.stage === "Exam") {
        return true;
      }

      return false;
    },
    [devicesWithExams]
  );

  useEffect(() => {
    if (deviceLinkPatient?.val && deviceLinkPatient?.val !== "")
      fetchAndFilterExams();
  }, [deviceLinkPatient, fetchAndFilterExams]);

  const unlinkDeviceModal = useMemo(
    () => (
      <UnlinkDeviceModal
        open={unlinkDeviceModalOpen}
        onClose={handleUnlinkDeviceClose}
        onConfirm={() => handleUnlinkDevice()}
        onCancel={() => setUnlinkDeviceModalOpen(false)}
        loading={unlinkLoading}
      />
    ),
    [handleUnlinkDevice, unlinkDeviceModalOpen, unlinkLoading]
  );
  const linkDeviceModal = useMemo(
    () => (
      <LinkDeviceModalGeneral
        open={linkDeviceModalOpen}
        onClose={handleLinkDeviceClose}
        onConfirm={handleLinkDevice}
        onCancel={() => setLinkDeviceModalOpen(false)}
        patientOptions={patients}
        examsOptions={availableExams}
        deviceLinkPatient={deviceLinkPatient}
        setDeviceLinkPatient={setDeviceLinkPatient}
        deviceLinkExam={deviceLinkExam}
        setDeviceLinkExam={setDeviceLinkExam}
        loading={linkLoading}
      />
    ),
    [
      availableExams,
      deviceLinkExam,
      deviceLinkPatient,
      handleLinkDevice,
      linkDeviceModalOpen,
      linkLoading,
      patients,
    ]
  );

  const unlinkSuccessMessage = useMemo(
    () => (
      <SnackbarMessage
        open={unlinkSuccessToast}
        onClose={() => setUnlinkSuccessToast(false)}
        success
        text={t("devices_unlink_success")}
      />
    ),
    [t, unlinkSuccessToast]
  );
  const unlinkFailureMessage = useMemo(
    () => (
      <SnackbarMessage
        open={unlinkFailureToast}
        onClose={() => setUnlinkFailureToast(false)}
        text={t("devices_unlink_error")}
      />
    ),
    [t, unlinkFailureToast]
  );
  // {/* Link device success/failure toasts */}
  const linkSuccessMessage = useMemo(
    () => (
      <SnackbarMessage
        open={linkSuccessToast}
        onClose={() => setLinkSuccessToast(false)}
        success
        text={t("device_link_success")}
      />
    ),
    [linkSuccessToast, t]
  );
  const linkFailureMessage = useMemo(
    () => (
      <SnackbarMessage
        open={linkFailureToast}
        onClose={() => setLinkFailureToast(false)}
        text={t("devices_link_error")}
      />
    ),
    [linkFailureToast, t]
  );

  return {
    unlinkLoading,
    linkLoading,
    unlinkDeviceModal,
    linkDeviceModal,
    unlinkSuccessMessage,
    unlinkFailureMessage,
    linkSuccessMessage,
    linkFailureMessage,
    handleUnlinkDeviceClick,
    handleLinkDeviceClick,
    checkIfDeviceHasExamInProgress,
  };
};
