import { Box, Grid, Typography } from "@mui/material";
import { Fragment, useCallback, useContext, useMemo } from "react";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";
import { useTranslation } from "react-i18next";
import Recenter from "../../../../assets/images/operating-manual/4/recenter.png";
import Calibration from "../../../../assets/images/icons/calibration.png";
import PasswordReset from "../../../../assets/images/operating-manual/6/6.5.png";
import Terminate from "../../../../assets/images/operating-manual/6/6.6.png";
import LiveUpdate from "../../../../assets/images/operating-manual/appendix/c/live-update.png";
import Edit from "../../../../assets/images/operating-manual/appendix/c/edit.png";
import Transfer from "../../../../assets/images/operating-manual/appendix/c/transfer.png";

const FAQ = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  const makeSectionWrapper = useCallback(
    (id, title, children) => (
      <Box id={id} sx={{ ...sectionContainerStyle, padding: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background:
              "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
            color: "white",
            p: 1,
            pl: 4,
            borderRadius: 2,
            mb: 2,
            boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          <Typography variant="h6" fontWeight={"bold"} color={"white"}>
            {title}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pl: 4,
            pr: 4,
            gap: 2,
          }}
        >
          {children}
        </Box>
      </Box>
    ),
    [sectionContainerStyle]
  );

  const TextWithIcons = useCallback(
    ({ textParts, icons }) => {
      return (
        <Typography>
          {textParts.map((part, index) => (
            <Fragment key={index}>
              {t(part)}{" "}
              {icons[index] && (
                <Box
                  component="img"
                  src={icons[index].src}
                  alt={icons[index].alt}
                  sx={{
                    width: icons[index].width,
                    verticalAlign: "middle",
                    mx: 0.5,
                    display: "inline-block",
                  }}
                />
              )}
            </Fragment>
          ))}
        </Typography>
      );
    },
    [t]
  );

  const Sections = useMemo(
    () => [
      makeSectionWrapper(
        "faq-new-exam",
        t("operating_manual_faq_new_exam"),
        <>
          <div class="p-2 bg-gray-100 text-gray-700">
            {t("operating_manual_faq_note")}
          </div>
          <Typography>{t("operating_manual_faq_new_exam_note")}</Typography>
        </>
      ),
      makeSectionWrapper(
        "faq-controller-visibility",
        t("operating_manual_faq_controller_visibility"),
        <Typography>
          {t("operating_manual_faq_controller_visibility_note")}
        </Typography>
      ),
      makeSectionWrapper(
        "faq-room-off-centre",
        t("operating_manual_faq_room_off_centre"),
        <TextWithIcons
          textParts={[
            "operating_manual_faq_room_off_centre_note1",
            "operating_manual_faq_room_off_centre_note2",
            "operating_manual_faq_room_off_centre_note3",
          ]}
          icons={[
            { src: Recenter, alt: "Recenter", width: "5%" },
            { src: Calibration, alt: "Calibration", width: "3.5%" },
          ]}
        />
      ),
      makeSectionWrapper(
        "faq-vr-reset",
        t("operating_manual_faq_vr_reset"),
        <Typography>
          {t("operating_manual_faq_vr_reset_note_one")}
          <Box>
            <span class="font-bold">
              {t("operating_manual_faq_vr_reset_note_six")}
            </span>
            {t("operating_manual_faq_vr_reset_note_two")}
          </Box>
          <Box>
            <span class="font-bold">
              {t("operating_manual_faq_vr_reset_note_three")}
            </span>
            {t("operating_manual_faq_vr_reset_note_four")}
          </Box>
          <Box>
            <span class="font-bold">
              {t("operating_manual_faq_vr_reset_note_five")}.
            </span>
          </Box>
        </Typography>
      ),
      makeSectionWrapper(
        "faq-reset-password",
        t("operating_manual_faq_reset_password"),
        <Grid container>
          <Grid item xs={12} sm={7}>
            {t("operating_manual_faq_reset_password_note")}
          </Grid>
          <Grid item xs={12} sm={5}>
            <img src={PasswordReset} alt="Reset Password" />
          </Grid>
        </Grid>
      ),
      makeSectionWrapper(
        "faq-edit-exam-settings",
        t("operating_manual_faq_edit_exam_settings"),
        <TextWithIcons
          textParts={[
            "operating_manual_faq_edit_exam_settings_note1",
            "operating_manual_faq_edit_exam_settings_note2",
            "operating_manual_faq_edit_exam_settings_note3",
            "operating_manual_faq_edit_exam_settings_note4",
          ]}
          icons={[
            { src: LiveUpdate, alt: "Live Update", width: "3%" },
            { src: Terminate, alt: "Terminate", width: "4%" },
            { src: Edit, alt: "Edit", width: "3%" },
          ]}
        />
      ),

      makeSectionWrapper(
        "faq-move-exam-patient",
        t("operating_manual_faq_move_exam_patient"),
        <TextWithIcons
          textParts={[
            "operating_manual_faq_move_exam_patient_note1",
            "operating_manual_faq_move_exam_patient_note2",
          ]}
          icons={[{ src: Transfer, alt: "Transfer", width: "3%" }]}
        />
      ),
    ],
    [makeSectionWrapper, t]
  );

  return (
    <>
      <Typography variant="h5" fontWeight={"bold"}>
        6. FAQ
      </Typography>
      <Box
        sx={{
          mb: 4,
        }}
      >
        {Sections.map((section, index) => (
          <div key={index}>{section}</div>
        ))}
      </Box>
    </>
  );
};

export default FAQ;
