import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OperatingManualStyleContext } from "../../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
import Headset from "../../../../../assets/images/operating-manual/head-mounted-device/headset.png";
import PicoBlue from "../../../../../assets/images/operating-manual/head-mounted-device/Pico-Blue.png";
import PicoRed from "../../../../../assets/images/operating-manual/head-mounted-device/Pico-Red.png";
import PicoGreen from "../../../../../assets/images/operating-manual/head-mounted-device/Pico-Green.png";
import PicoOrange from "../../../../../assets/images/operating-manual/head-mounted-device/Pico-Orange.png";
import PicoFlashingBlue from "../../../../../assets/images/operating-manual/head-mounted-device/Pico-Blue-Flashing.png";
import PicoFlashingRed from "../../../../../assets/images/operating-manual/head-mounted-device/Pico-Red-Flashing.png";
import PicoBlueRed from "../../../../../assets/images/operating-manual/head-mounted-device/Pico-BlueRed.png";
import PicoOff from "../../../../../assets/images/operating-manual/head-mounted-device/Pico-Off.png";
import Controller from "../../../../../assets/images/operating-manual/head-mounted-device/controller.png";
import PowerButton from "../../../../../assets/images/operating-manual/head-mounted-device/headset/power-button.png";
import UsbcPort from "../../../../../assets/images/operating-manual/head-mounted-device/headset/usbc-port.png";
import Volume from "../../../../../assets/images/operating-manual/head-mounted-device/headset/volume.png";
import Select from "../../../../../assets/images/operating-manual/head-mounted-device/controller/select-button.png";
import Pause from "../../../../../assets/images/operating-manual/head-mounted-device/controller/pause-button.png";
import Recenter from "../../../../../assets/images/operating-manual/head-mounted-device/controller/recenter-button.png";
import AdminSettings from "../../../../../assets/images/operating-manual/head-mounted-device/controller/power-admin-settings-button.png";
import Trigger from "../../../../../assets/images/operating-manual/head-mounted-device/controller/trigger.png";

const HeadMountedDeviceGuide = () => {
  const { t } = useTranslation();
  const imgAttributes = [
    [
      { src: PowerButton, alt: "power-button" },
      { src: UsbcPort, alt: "usbc-port" },
      { src: Volume, alt: "volume" },
    ],
    [
      { src: Select, alt: "select-button" },
      { src: Pause, alt: "pause-button" },
      { src: Recenter, alt: "recenter-button" },
      { src: AdminSettings, alt: "admin-settings-button" },
      { src: Trigger, alt: "trigger-button" },
    ],
    [
      { src: PicoGreen, alt: "pico-green" },
      { src: PicoFlashingBlue, alt: "pico-flashing-blue" },
      { src: PicoBlue, alt: "pico-blue" },
      { src: PicoFlashingRed, alt: "pico-flashing-red" },
      { src: PicoRed, alt: "pico-red" },
      { src: PicoOff, alt: "pico-off" },
      { src: PicoOrange, alt: "pico-orange" },
    ],
    [
      { src: PicoFlashingBlue, alt: "pico-flashing-blue" },
      { src: PicoOff, alt: "pico-off" },
      { src: PicoBlue, alt: "pico-blue" },
      { src: PicoBlueRed, alt: "pico-blue-red" },
    ],
  ];
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);

  return (
    <Box
      id="head-mounted-device-guide"
      sx={{ ...sectionContainerStyle, padding: 3 }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background:
            "linear-gradient(102deg, #f18e8e -17.62%, #996ad2 70.87%, #5d5cfe 97.31%)",
          color: "white",
          p: 1,
          pl: 4,
          borderRadius: 2,
          mb: 2,
          boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        <Typography variant="h6" gutterBottom fontWeight={"bold"}>
          3.3 {t("operating_manual_head_mounted_device_guide_title")}
        </Typography>
      </Box>
      <div className="mx-auto max-w-4xl">
        <Grid
          container
          display={"flex"}
          justifyContent="center"
          alignItems="center"
        >
          <img src={Headset} width={"80%"} alt="headset" />
        </Grid>

        {/* Headset Controls */}
        <div
          className="mx-auto my-5 bg-gray-100 p-4 rounded-lg shadow-md"
          id="headset-controls"
        >
          {[...Array(3)].map((_, index) => (
            <Grid container spacing={2} alignItems="center" key={index}>
              <Grid item xs={1}>
                <Typography fontWeight="bold">{index + 1}</Typography>
              </Grid>
              <Grid item xs={2}>
                <img
                  src={imgAttributes[0][index].src}
                  alt={imgAttributes[0][index].alt}
                  style={{ width: "80%", objectFit: "cover" }}
                />
              </Grid>
              <Grid item xs={9}>
                <Typography fontWeight="bold">
                  {t(
                    `operating_manual_head_mounted_device_guide_headset${index + 1}_header`
                  )}
                </Typography>
                <Typography>
                  {t(
                    `operating_manual_head_mounted_device_guide_headset${index + 1}_content`
                  )}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </div>

        {/* Status Indicators */}
        <Typography gutterBottom fontWeight="bold" className="mt-5">
          {t("operating_manual_head_mounted_device_guide_status_indicators")}
        </Typography>
        <Grid
          container
          className="mx-auto mb-5 bg-gray-100 p-4 rounded-lg shadow-md"
        >
          {[...Array(7)].map((_, index) => (
            <Grid
              container
              item
              xs={12}
              md={6}
              alignItems="center"
              key={index}
              className="mb-4"
              spacing={2}
            >
              <Grid item xs={1}>
                <img
                  src={imgAttributes[2][index].src}
                  alt={imgAttributes[2][index].alt}
                  style={{ width: "80%", objectFit: "cover" }}
                />
              </Grid>
              <Grid item xs={3.5}>
                <Typography fontWeight="bold">
                  {t(
                    `operating_manual_head_mounted_device_guide_status_indicators${index + 1}_header`
                  )}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography>
                  {t(
                    `operating_manual_head_mounted_device_guide_status_indicators${index + 1}_content`
                  )}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>

        {/* Controller Section */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className="my-4"
        >
          <img
            src={Controller}
            alt="controller"
            style={{ maxWidth: "100%", borderRadius: "8px" }}
          />
        </Grid>

        {/* Controller Controls */}
        <div className="mx-auto my-5 bg-gray-100 p-4 rounded-lg shadow-md">
          {[...Array(5)].map((_, index) => (
            <Grid
              container
              spacing={2}
              alignItems="center"
              key={index}
              className="mb-4"
            >
              <Grid item xs={1}>
                <Typography fontWeight="bold">{index + 1}</Typography>
              </Grid>
              <Grid item xs={2}>
                <img
                  src={imgAttributes[1][index].src}
                  alt={imgAttributes[1][index].alt}
                  style={{ width: "80%", objectFit: "cover" }}
                />
              </Grid>
              <Grid item xs={9}>
                <Typography fontWeight="bold">
                  {t(
                    `operating_manual_head_mounted_device_guide_controller${index + 1}_header`
                  )}
                </Typography>
                <Typography>
                  {t(
                    `operating_manual_head_mounted_device_guide_controller${index + 1}_content`
                  )}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </div>

        {/* Status Indicators */}
        <Typography gutterBottom fontWeight="bold" className="mt-5">
          {t("operating_manual_head_mounted_device_guide_status_indicators")}
        </Typography>
        <Grid
          container
          className="mx-auto mb-5 bg-gray-100 p-4 rounded-lg shadow-md"
        >
          {[...Array(4)].map((_, index) => (
            <Grid
              container
              item
              xs={12}
              md={6}
              alignItems="center"
              key={index}
              className="mb-4"
              spacing={2}
            >
              <Grid item xs={1}>
                <img
                  src={imgAttributes[3][index].src}
                  alt={imgAttributes[3][index].alt}
                  style={{ width: "80%", objectFit: "cover" }}
                />
              </Grid>
              <Grid item xs={3.5}>
                <Typography fontWeight="bold">
                  {t(
                    `operating_manual_head_mounted_device_guide_controller_status_indicators${index + 1}_header`
                  )}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography>
                  {t(
                    `operating_manual_head_mounted_device_guide_controller_status_indicators${index + 1}_content`
                  )}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    </Box>
  );
};

export default HeadMountedDeviceGuide;
