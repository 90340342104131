import { Box, Typography, Grid } from "@mui/material";
import { OperatingManualStyleContext } from "../../../../contexts/OperatingManualStyleContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Add from "../../../../assets/images/operating-manual/appendix/c/add.png";
import Comment from "../../../../assets/images/operating-manual/appendix/c/comment.png";
import Search from "../../../../assets/images/operating-manual/appendix/c/search.png";
import LiveUpdate from "../../../../assets/images/operating-manual/appendix/c/live-update.png";
import Profile from "../../../../assets/images/operating-manual/appendix/c/profile.png";
import LinkDevice from "../../../../assets/images/operating-manual/appendix/c/link-device.png";
import Clinic from "../../../../assets/images/operating-manual/appendix/c/clinic.png";
import BatteryLow from "../../../../assets/images/operating-manual/appendix/c/low-battery.png";
import ViewResults from "../../../../assets/images/operating-manual/appendix/c/view-results.png";
import Battery60 from "../../../../assets/images/operating-manual/appendix/c/battery-60.png";
import Duplicate from "../../../../assets/images/operating-manual/appendix/c/duplicate.png";
import BatteryFull from "../../../../assets/images/operating-manual/appendix/c/battery-full.png";
import Edit from "../../../../assets/images/operating-manual/appendix/c/edit.png";
import Volume from "../../../../assets/images/operating-manual/appendix/c/volume-slider.png";
import Transfer from "../../../../assets/images/operating-manual/appendix/c/transfer.png";
import Recenter from "../../../../assets/images/operating-manual/appendix/c/calibrate.png";
import ControllerSoundOff from "../../../../assets/images/operating-manual/appendix/c/controller-beep-off.png";
import ControllerSoundOn from "../../../../assets/images/operating-manual/appendix/c/controller-beep-on.png";
import ControllerConnected from "../../../../assets/images/operating-manual/appendix/c/controller-online.png";
import ControllerDisconnected from "../../../../assets/images/operating-manual/appendix/c/controller-offline.png";

const AppendixCPortalIconGlossary = () => {
  const { t } = useTranslation();
  const { sectionContainerStyle } = useContext(OperatingManualStyleContext);
  const imgAttributes = [
    [
      { src: Add, alt: "add" },
      { src: Comment, alt: "comment" },
      { src: Search, alt: "search" },
      { src: LiveUpdate, alt: "live-update" },
      { src: Profile, alt: "profile" },
      { src: LinkDevice, alt: "link-device" },
      { src: Clinic, alt: "clinic" },
      { src: BatteryLow, alt: "battery-low" },
      { src: ViewResults, alt: "view-results" },
      { src: Battery60, alt: "battery-60" },
      { src: Duplicate, alt: "duplicate" },
      { src: BatteryFull, alt: "battery-full" },
      { src: Edit, alt: "edit" },
      { src: Volume, alt: "volume" },
      { src: Transfer, alt: "transfer" },
      { src: Recenter, alt: "recenter" },
      { src: ControllerSoundOff, alt: "controller-sound-off" },
      { src: ControllerSoundOn, alt: "controller-sound-on" },
      { src: ControllerConnected, alt: "controller-connected" },
      { src: ControllerDisconnected, alt: "controller-disconnected" },
    ],
  ];

  return (
    <>
      <Box sx={{ ...sectionContainerStyle, padding: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            background:
              "linear-gradient(102deg, #5d5cfe -17.62%, #996ad2 30.87%, #f18e8e 87.31%)",
            color: "white",
            p: 1,
            pl: 4,
            borderRadius: 2,
            mb: 2,
            boxShadow: "0px 0.625rem 3rem 0px rgba(0, 0, 0, 0.06)",
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            {t("operating_manual_appendix_c")}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pl: 4,
            pr: 4,
            mb: 4,
          }}
        >
          <Grid
            container
            xs={12}
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            {[...Array(20)].map((_, index) => (
              <Grid
                container
                item
                xs={6}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={3}>
                  <img
                    src={imgAttributes[0][index].src}
                    alt={imgAttributes[0][index].alt}
                    style={{
                      height: "60%",
                      maxWidth: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Typography>
                    {t(`operating_manual_appendix_c_${index + 1}`)}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AppendixCPortalIconGlossary;
